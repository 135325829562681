import React, {useState, useRef} from 'react';
import {Modal} from 'react-bootstrap';
import {Button} from './styled-components';
import PropTypes from 'prop-types';
// import '../index.scss';
import Style from './index.module.scss';
import dayjs from 'dayjs';

export const ModalPopUp = ({modalShow, modalTitle, modalBody,
  closeHandler, confirmHandler, assessmentBody, schedule,
  leadId, regId, agentType, agentName}) =>{
  const [watchNowLink, setwatchNowLink] = useState('');
  const linkRef = useRef(null);
  const assignmentData = assessmentBody?.assessments?.[0];
  const dateToday = dayjs();
  const startDate = dayjs(schedule?.[0].start_time);
  const differenceDay = dayjs(dateToday).add(7, 'd').diff(startDate, 'd');

  /**
   *
   * @param {*} leadId
   * @param {*} regId
   * @param {*} agentType
   * @param {*} agentName
   */
  function handleWatchNowClick(leadId, regId, agentType, agentName) {
    let offerDomain = 'https://offers.shawacademy.com/';
    offerDomain +=
      `reschedule-now?leadId=${leadId}&regId=${regId}&lesson_num=1`;
    offerDomain += `&utm_source=${agentType}-reschedule`;
    offerDomain += `&utm_medium=email&utm_campaign=${agentName}`;
    setwatchNowLink(() => {
      return offerDomain;
    });
    setTimeout(() => {
      const linkRefObject = linkRef?.current;
      linkRefObject.select();
      document.execCommand('copy');
      window.open(`${offerDomain}`, '_blank');
    }, 500);
  }


  return (
    <Modal show={modalShow} onHide={closeHandler}
      className={Style.deleteAccoutModal}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={Style.scheduleModal}>
        {
          schedule &&
          schedule.map((lesson, index)=>(
            <div key={index}>
              {`Lesson ${index+1} - 
              ${dayjs(lesson?.start_time).format('DD MMM hh A')}`}
              { (index == 0) && (differenceDay >= 0 && differenceDay < 7) &&
              <span className={Style.watchNow}
                onClick={() =>
                  handleWatchNowClick(leadId, regId, agentType,
                      agentName.replace(/ +/g, ''))}>
                Watch now

              </span> }
            </div>
          ))
        }
        {
          !schedule && modalBody
        }
        <input
          style={{position: 'absolute', top: '-1000px'}}
          ref={linkRef} value={watchNowLink} />
      </Modal.Body>
      <Modal.Body className={modalTitle === 'Assignment Info' ?
      Style.show : Style.hide}>
        <div style={{'textAlign': 'left',
          'margin': '0 auto',
          'width': '72%'}} className={assignmentData ? Style.show : Style.hide}>
          Slug: <b>{assignmentData?.metadata?.course_slug} </b><br />
          Attempts: <b>{assignmentData?.attempts} </b><br />
          Top Score: <b>{assignmentData?.top_score} </b><br />
          Total Marks: <b>{assignmentData?.total_marks} </b><br />
          Percentile: <b>{getPercentile(assignmentData)} </b><br />
          Result: <b>{getResult(getPercentile(assignmentData))}</b> <br />
          Last Updated on: <b>
            {
              assignmentData?.updated_at &&
                dayjs(assignmentData?.updated_at)?.format('D MMM yy')
            }
          </b> <br />
          <button onClick={() => openCert(assignmentData)}
            style={{'margin': '5px 0',
              'padding': '5px 10px',
              'color': '#ffffff',
              'fontFamily': 'Poppins-Regular',
              'borderRadius': '50px',
              'fontWeight': 'normal',
              'border': 'none',
            }}>
            View Ecert
          </button>
        </div>
        <div className={!assignmentData ? Style.show : Style.hide}>
          No Info found!!
        </div>
      </Modal.Body>
      <Modal.Footer className={modalTitle === 'Assignment Info' ?
      Style.hide : Style.show}>
        <Button variant="secondary" onClick={confirmHandler}>
            Yes
        </Button>
        <Button variant="secondary" onClick={closeHandler}>
            No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

/**
 *
 *
 * @param {*} data
 * @return {*} percentile
 */
function getPercentile(data) {
  if (data) {
    const studentScore = data?.top_score;
    const totalScore = data?.total_marks;
    return Number(((studentScore/totalScore) * 100).toFixed(2)) === 'NaN' ? '' :
      Number(((studentScore/totalScore) * 100).toFixed(2)) > 100 ? 100 :
      Number(((studentScore/totalScore) * 100).toFixed(2));
  }
}

/**
 *
 *
 * @param {*} assignmentInfo
 */
function openCert(assignmentInfo) {
  const domain = process.env.ShawWebsiteDomain;
  const param = `app/en/module-certificate/?form_id=`;
  const formId = assignmentInfo?.metadata?.typeform?.form?.id;
  const responseId = assignmentInfo?.metadata?.typeform?.response?.token;
  window.open(`${domain}${param}${formId}&response_id=${responseId}`, '_blank');
}


/**
 *
 *
 * @param {*} data
 * @return {*} string
 */
function getResult(data) {
  let breakDown ='FAIL';
  if (data >= 85) {
    breakDown ='DISTINCTION';
  } else if (data >= 70) {
    breakDown ='MERIT';
  } else if (data >= 50) {
    breakDown ='PASS';
  }
  return breakDown;
}

ModalPopUp.propTypes = {
  modalShow: PropTypes.bool,
  modalTitle: PropTypes.string,
  modalBody: PropTypes.string,
  closeHandler: PropTypes.func,
  confirmHandler: PropTypes.func,
  assessmentBody: PropTypes.object,
  schedule: PropTypes.array,
  leadId: PropTypes.any,
  regId: PropTypes.any,
  agentName: PropTypes.any,
  agentType: PropTypes.any,
};
