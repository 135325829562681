import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as trackAction from '../../../Shared/TrackActionService';
import {DisplayLayoutContainer,
  Button,
  Title,
  RowFlexWrapper,
  TitleContainer,
} from '../styled-components';
import * as apiService from '../../../../api';
import * as CommonService from '../CommonService';
import AutoSuggest from 'react-autosuggest';
import {Spinner} from 'react-bootstrap';
import {RescheduleModal} from '../RegisteredCourses/RescheduleModal';
import Styles from './index.module.scss';
import styles from '../index.module.scss';
import dayjs from 'dayjs';
import cookie from 'react-cookies';

const AddNewCourse = (props) => {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSlug, setSelectedSlug] = useState({
    'prefix': '',
    'value': 'module1',
    'key': 1,
  });
  const [showSpinner, setShowSpinner] = useState(false);
  const [isAddCourseDisabled, setIsAddCourseDisabled] =useState(true);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [failureMessage, setFailureMessage] = useState(null);
  const courseList=props.allCourses;
  const modules=[
    {name: 'module1'},
    {name: 'module2'},
    {name: 'module3'},
    {name: 'module4'},
  ];
  useEffect(()=>{
    let isAddCourseBtnDisabled=true;
    if (props.studentRegistrations && selectedSlug?.name) {
      const strLength = selectedSlug?.value?.length;
      const moduleNo = Number(selectedSlug?.value?.charAt(strLength-1))-1;
      let prefixAdded = false;
      for (let i=0; i<props?.studentRegistrations?.length; i++) {
        // add prefix from modules in studentRegistrations
        if (props.studentRegistrations[i]?.relationships?.
            modules?.data?.length === 4 && !prefixAdded) {
          prefixAdded=true;
          const prefix = moduleNo!==0?props.studentRegistrations[i]?.
              relationships?.modules?.data[moduleNo]?.id.split('-')[0]+'-':'';
          setSelectedSlug({
            ...selectedSlug,
            prefix: prefix,
            slug: prefix + selectedSlug.name,
          });
        }
        if (props.studentRegistrations[i]?.attributes?.courseslug !==
          selectedSlug.name) {
          isAddCourseBtnDisabled=true;
        } else {
          isAddCourseBtnDisabled=false;
          break;
        }
      }
      if (isAddCourseBtnDisabled && selectedSlug.value === 'module1') {
        isAddCourseBtnDisabled=false;
      }
    }
    setIsAddCourseDisabled(isAddCourseBtnDisabled);
  }, [props.studentRegistrations, selectedSlug.name, selectedSlug.value]);

  useEffect(()=>{
    if (successMessage || failureMessage) {
      setTimeout(()=>{
        setSuccessMessage(null);
        setFailureMessage(null);
      }, 3000);
    }
  }, [successMessage, failureMessage]);

  const lowerCasedCourseList = courseList?.map((course) => {
    return {
      name: course?.coursename.toLowerCase(),
      slug: course?.courseslug,
      module: course?.modules,
    };
  });

  const getSuggestions=(value)=> {
    return lowerCasedCourseList.filter((course) =>
      course.name.includes(value.trim().toLowerCase()),
    );
  };

  const moduleDropdownHandler = (e) =>{
    const selectedIndex = e.target.options.selectedIndex;
    setSelectedSlug({
      ...selectedSlug,
      value: e.target.value,
      key: selectedIndex,
    });
  };
  const randomDate = (start, end) => {
    return dayjs(new Date(start.getTime() +
    Math.random() * (end.getTime() - start.getTime())));
  };
  const registerCourse = (schedule1, schedule2, lessons) =>{
    const {firstDayOfWeek, secondDayOfWeek} =
    CommonService.getDaysOfWeek(schedule1, schedule2);
    // get requestObject
    const createScheduleObject = {
      courseStartDate: schedule1.toISOString(),
      courseSlug: selectedSlug?.slug,
      firstDayOfWeek,
      secondDayOfWeek,
      firstDayHour: parseInt(schedule1.format('H')),
      secondDayHour: parseInt(schedule2.format('H')),
      lessons,
      leadId: props?.userData?.userId,
    };
    const requestObj = CommonService.
        createScheduleReqObject(createScheduleObject);

    new apiService.default.Schedule().create(requestObj)
        .then((response) => {
          const result = response.data.data;
          if (result) {
            trackAction.registerModuleAction({
              'moduleNumber': parseInt(Number(selectedSlug?.value.
                  substr(selectedSlug.value.length-1)), 10),
              'courseSlug': result.attributes.course_slug,
              'facultySlug': result.attributes.course.faculty,
              'locale': 'en',
              'Goal': 2,
              'scheduleType': cookie.load('country') === 'gb' ? 'v2' :
                'v1',
            });
            setSuccessMessage('Course recovered successfully');
            props.getRegisteredCourses(props?.userData?.userId);
          } else if (response.data.errors[0].code == '409') {
            setTimeout(()=>{
              setFailureMessage('Conflict-Data already added');
            }, 2000);
          }
          setShowSpinner(false);
        })
        .catch((error) => {
          setShowSpinner(false);
          setFailureMessage('Error in scheduling course');
        });
  };

  const recoverAgent = () =>{
    if (selectedSlug?.slug) {
      setShowSpinner(true);

      // build schedule
      const schedule1 = randomDate(new Date(2012, 0, 1), new Date(2013, 0, 1));
      const schedule2 = schedule1.clone().add(1, 'days');
      if (selectedSlug?.modules[selectedSlug?.key]) {
        const lessonLength = selectedSlug?.
            modules[selectedSlug?.key]?.lessons?.length;

        // get lessonArray
        const lessons = CommonService.
            getLessonArray(schedule1, schedule2, lessonLength);

        // API call for adding course
        registerCourse(schedule1, schedule2, lessons);
      } else {
        setShowSpinner(false);
        setTimeout(()=>{
          setFailureMessage('module not present');
        }, 2000);
      }
    } else {
      setShowSpinner(false);
      setTimeout(()=>{
        setFailureMessage('Course not selected');
      }, 2000);
    }
  };
    /**
   * reschedule cta handler
   * @param {*} lessons
   * @param {*} regId
   */
  // const handleRescheduleModal = (lessons, regId) =>{
  //   setLessons(lessons);
  //   setRegisteredId(regId);
  //   setShowRescheduleModal(true);
  // };
  const addNewCourse = () => {
    let pageKind = 'CsLoginDashboard';
    if (props?.csAgent) {
      pageKind = 'CsLoginDashboard';
    } else if (props?.reactivationAgent) {
      pageKind = 'ReactivationAgentDashboard';
    }
    trackAction.ctaClickAction({
      'studentId': props?.userData?.userId,
      'cta': props.title,
      'action': props?.csAgent?'RecoveringCourse':'AddCourse',
      'courseSlug': selectedSlug?.slug,
      'moduleNumber': Number(selectedSlug?.value.
          substr(selectedSlug.value.length-1)),
      pageKind,
    });
    if (props?.csAgent) {
      recoverAgent();
    } else {
      setShowRescheduleModal(true);
    }
  };
  return (
    <>
      {
        (successMessage || failureMessage) &&
          <div style={{color: successMessage?'green':'red',
            fontSize: '12px',
            margin: '0 10px 0px 0px'}}>
            {successMessage?successMessage:failureMessage}
          </div>
      }
      <DisplayLayoutContainer>
        <RowFlexWrapper>
          <Title>{props.title}</Title>
          <TitleContainer>
            {
                showSpinner ?
                <div style={{margin: '0 10px 0px 0px'}}>
                  <Spinner animation="border" />
                </div> : null
            }
            <Button
              onClick={()=>addNewCourse()}
              disabled={isAddCourseDisabled}
              className={isAddCourseDisabled?Styles.disabled:''}
            >
              {props.buttonName}
            </Button>
          </TitleContainer>
        </RowFlexWrapper>
        <RowFlexWrapper
          style={{
            'marginTop': '10px',
            'justifyContent': 'unset',
          }}
        >
          <AutoSuggest
            suggestions={suggestions}
            onSuggestionsClearRequested={() => setSuggestions([])}
            onSuggestionsFetchRequested={({value}) => {
              setValue(value);
              setSuggestions(getSuggestions(value));
            }}
            onSuggestionSelected={(_, {suggestion}) =>
              setSelectedSlug({
                ...selectedSlug,
                name: suggestion.slug,
                modules: suggestion.module,
              })
            }
            getSuggestionValue={(suggestion) => suggestion.name}
            renderSuggestion={(suggestion) => <span>{suggestion.name}</span>}
            inputProps={{
              placeholder: props?.csAgent ? 'Recover Course' : 'Add Course',
              value: value,
              onChange: (_, {newValue, method}) => {
                setValue(newValue);
              },
            }}
            highlightFirstSuggestion={true}
            className={styles.autoSuggestion}
          />
          {
            props?.csAgent &&
            <select
              onChange={(e)=>moduleDropdownHandler(e)}
              className={Styles.moduleDropdown}
              disabled = {props?.csAgent ? '' : 'disabled'}
            >
              {
                modules.map((module, index)=>{
                  return <option
                    value={module.name}
                    key={index}
                    selected = {index === 0 ? 'selected' : ''}
                  >
                    {module.name}
                  </option>;
                })
              }
            </select>
          }
        </RowFlexWrapper>
        <RescheduleModal
          modalShow={showRescheduleModal}
          closeHandler={()=>setShowRescheduleModal(false)}
          modalTitle={'Pick your class schedule'}
          leadId={props?.userData?.userId}
          slug={selectedSlug?.name}
          lessonsForCourse={selectedSlug?.modules?.[0]?.lessons}
          setShowSpinner={setShowSpinner}
          getRegisteredCourses={props?.getRegisteredCourses}
          setFailureMessage={setFailureMessage}
          setSuccessMessage={setSuccessMessage}
          timeZone={props?.userData?.timeZone}
        />
      </DisplayLayoutContainer>
    </>
  );
};

AddNewCourse.propTypes = {
  userData: PropTypes.object,
  allCourses: PropTypes.array,
  getRegisteredCourses: PropTypes.func,
  studentRegistrations: PropTypes.array,
  title: PropTypes.string,
  buttonName: PropTypes.string,
  csAgent: PropTypes.bool,
  reactivationAgent: PropTypes.bool,
};

export default AddNewCourse;
