import React, {useEffect, useState} from 'react';
import {Modal, Form, Col} from 'react-bootstrap';
import {Button} from '../styled-components';
import PropTypes from 'prop-types';
import '../../../../Application/Schedule/index.scss';
import * as apiService from '../../../../api/index';
import * as CommonService from '../CommonService';
import DatePicker from 'react-date-picker';
import Style from './index.module.scss';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import cookie from 'react-cookies';

dayjs.extend(isoWeek);
dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);

const daysArray = [
  'Sunday', 'Monday', 'Tuesday',
  'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const RescheduleModal = ({modalShow, modalTitle,
  closeHandler, leadId, regId, lessonsForCourse, setShowSpinner,
  getRegisteredCourses, setSuccessMessage, setFailureMessage,
  timeZone, slug}) =>{
  const [time1, setTime1] =useState(null);
  const [time2, setTime2] =useState(null);
  const [day1, setDay1] =useState(null);
  const [isDay1Selected, setDay1Selected] = useState(false);
  const [isDay2Selected, setDay2Selected] = useState(false);
  const [day2, setDay2] =useState(null);
  const [weekDay1, setWeekDay1] =useState(null);
  const [schedule1, setSchedule1] =useState(null);
  const [schedule2, setSchedule2] =useState(null);
  const [showButton, setShowButton] =useState(false);
  const [lessons, setLessons] = useState(null);
  const [minLimit, setMinLimit] = useState('');
  const [maxLimit, setMaxLimit] = useState('');
  const [defaultDate, setDefaultDate] = useState('');
  const [showError, setShowError] =useState(false);
  const [showCommonError, setCommonError] =useState(false);
  const dayArray = [
    'Monday', 'Tuesday', 'Wednesday',
    'Thursday', 'Friday', 'Saturday', 'Sunday',
  ];
  const timeArray = [
    '6 AM', '7 AM', '8 AM', '9 AM',
    '10 AM', '11 AM', '12 PM', '1 PM',
    '2 PM', '3 PM', '4 PM', '5 PM', '6 PM',
    '7 PM', '8 PM', '9 PM', '10 PM', '11 PM',
  ];

  useEffect(()=>{
    toggleConfirmationTextState();
  }, [isDay1Selected,
    day2, isDay2Selected, time1, time2, day1]);

  useEffect(()=>{
    const dateToday = dayjs();
    const minLimit = dayjs(dateToday).add(0, 'd').format('YYYY-MM-DD');
    const maxLimit = dayjs(dateToday).add(1, 'M').format('YYYY-MM-DD');
    setMinLimit(minLimit);
    setMaxLimit(maxLimit);
    setDefaultDate([].concat(minLimit));
    setDay1(dayjs().add(0, 'day'));
    setDay2(null);
    setSchedule2(null);
    setSchedule1(null);
    setDay1Selected(false);
    setDay2Selected(false);
  }, []);

  useEffect(()=>{
    if (showCommonError) {
      setCommonError(false);
    }
  }, [showCommonError]);

  useEffect(()=>{
    if (schedule1 && schedule2) {
      getHourDifference();
      buildScheduleLessonsArray();
    }
  }, [schedule1, schedule2]);

  const getHourDifference = () => {
    const hourDiff = Math.abs(dayjs.duration(schedule2?.diff(schedule1)).
        asHours());
    if (Math.round(hourDiff) < 2) {
      setShowButton(false);
      setShowError(true);
    } else {
      setShowButton(true);
      setShowError(false);
    }
  };

  const buildScheduleLessonsArray = () => {
    // const lessonsVar = [];
    // const lessonLength = Math.ceil(lessonsForCourse?.length / 2);

    // for (let i = 0; i < lessonLength; i++) {
    //   let day1Var = schedule1?.clone();
    //   day1Var = day1Var?.add(i*7, 'days').format();
    //   let day2Var = schedule2?.clone();
    //   day2Var = day2Var?.add(i*7, 'days').format();

    //   lessonsVar.push({
    //     number: lessonsVar.length + 1,
    //     start_time: day1Var,
    //   });

    //   lessonsVar.push({
    //     number: lessonsVar.length + 1,
    //     start_time: day2Var,
    //   });
    // }
    const lessonsVar = CommonService.
        getLessonArray(schedule1, schedule2, lessonsForCourse?.length);

    if (lessonsVar.length !== lessonsForCourse?.length) {
      lessonsVar.pop();
    }
    setLessons(lessonsVar);
  };

  const toggleConfirmationTextState = () => {
    if (isDay1Selected && isDay2Selected &&
      time1 && time2) {
      const hours1Var = parseInt(
          dayjs(time1, ['h A']).tz().format('HH:mm'));
      const hours2Var = parseInt(
          dayjs(time2, ['h A']).tz().format('HH:mm'));
      setSchedule1(day1?.set(
          'hour', hours1Var).set('minute', 0).set('second', 0));
      setSchedule2(day2?.set(
          'hour', hours2Var).set('minute', 0).set('second', 0));
    } else {
      setShowButton(false);
    }
  };
  const handleFirstHourChange=(e)=> {
    if (e.target.value != 0) {
      setTime1(e.target.value);
    } else {
      setSchedule1(null);
      setTime1(null);
    }
  };

  const handleSecondHourChange=(e)=> {
    if (e.target.value != 0) {
      setTime2(e.target.value);
    } else {
      setSchedule2(null);
      setTime2(null);
    }
  };

  const handleFirstDayChange = (value) =>{
    const selectedDay1Var = dayjs(value);

    setDefaultDate([].concat(selectedDay1Var));
    setDay1(selectedDay1Var);
    setWeekDay1(selectedDay1Var?.format('dddd'));

    if (isDay2Selected) {
      while (day1?.diff(day2, 'hours') > 0) {
        day2?.add(7, 'days');
      }
    }
    if (schedule1) {
      const hoursVar = parseInt(schedule1?.format('HH'));
      const schedule1Var = selectedDay1Var
          .set('hour', hoursVar).set('minute', 0).set('second', 0);
      setSchedule1(schedule1Var);
    }
    setDay1Selected(true);
  };

  const handleSecondDayChange = (e) => {
    const selectedWeekdayVar = daysArray.indexOf(e.target.value);
    let selectedDay2Var = dayjs().isoWeekday(selectedWeekdayVar);

    if (day1) {
      while (day1?.diff(selectedDay2Var, 'hours') > 0) {
        selectedDay2Var = dayjs(selectedDay2Var).add(7, 'days');
      }
    }
    setDay2(selectedDay2Var);

    if (time2) {
      const hoursVar =
        parseInt(dayjs(schedule2, ['h A'])?.format('HH:mm'));
      const schedule2Var = selectedDay2Var
          ?.set('hour', hoursVar).set('minute', 0).set('second', 0);

      setSchedule2(schedule2Var);
    }
    if (e.target.value != 0) {
      setDay2Selected(true);
    } else {
      setDay2Selected(false);
    }
  };

  const handleConfirmation = ()=> {
    setSuccessMessage(null);
    setFailureMessage(null);
    if (!showError) {
      // let firstDayOfWeek;
      // let secondDayOfWeek;
      // if (schedule1?.day() === 0) {
      //   firstDayOfWeek = 7;
      // } else {
      //   firstDayOfWeek = schedule1?.day();
      // }
      // if (schedule2?.day() === 0) {
      //   secondDayOfWeek = 7;
      // } else {
      //   secondDayOfWeek = schedule2?.day();
      // }
      const {firstDayOfWeek, secondDayOfWeek} =
      CommonService.getDaysOfWeek(schedule1, schedule2);

      const courseStartDate = !regId ? schedule1.toISOString() : undefined;
      const courseSlug = !regId ? slug : undefined;
      const id = regId ? regId : undefined;
      const requestObj = {
        'data': {
          'type': 'registrations',
          'attributes': {
            'course_start_date': courseStartDate,
            'course_slug': courseSlug,
            'schedule': {
              'first_day_of_week': firstDayOfWeek,
              'second_day_of_week': secondDayOfWeek,
              'first_day_hour': parseInt(schedule1?.format('H')),
              'second_day_hour': parseInt(schedule2?.format('H')),
              'lessons': lessons,
            },
          },
          'relationships': {
            'student': {
              'data': {
                'type': 'student',
                'id': leadId,
              },
            },
          },
          id,
        },
      };
      setShowSpinner(true);
      const apiCallType = regId ?
        new apiService.default.Schedule(regId).update(requestObj) :
        new apiService.default.Schedule().create(requestObj);
      apiCallType
          .then((response) => {
            if (response) {
              setShowSpinner(false);
              getRegisteredCourses(leadId);
              setSuccessMessage('course rescheduled successfully');
              const result = response.data.data;
              window.dataLayer.push({
                'event': 'registration-action',
                'registration-param': {
                  'studentId': leadId,
                  'action': 'Rescheduled',
                  'moduleNumber': 1,
                  'courseSlug': result.attributes.course_slug,
                  'facultySlug': result.attributes.course.faculty,
                  'locale': 'en',
                  'scheduleType': cookie.load('country') === 'gb' ? 'v2' :
                    'v1',
                },
              });
            }
          })
          .catch((error) => {
            setShowSpinner(false);
            setCommonError(true);
            setFailureMessage('error while reschedule course');
          });
    }
    closeHandler();
  };
  const closePopup = () =>{
    setTime1(null);
    setTime2(null);
    setDay2(null);
    setDay1Selected(false);
    setDay2Selected(false);
    setWeekDay1(null);
    setSchedule1(null);
    setShowError(null);
    setSchedule2(null);
    closeHandler();
  };

  return (
    <Modal show={modalShow} onHide={closePopup}
      className={Style.deleteAccountModal}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={Style.rescheduleModal}>
        <div className={Style.itemOne}>
          <div className={Style.schedulePickerKeys}>
            <Form.Row className={Style.classScheduleRow}>
              <div className={Style.fieldContainer}>
                <Form.Label className={Style.fieldLabel}>
                  Pick a start date
                </Form.Label>
                <DatePicker
                  minDate={new Date(minLimit)}
                  maxDate={new Date(maxLimit)}
                  value={defaultDate}
                  onChange={(value) => handleFirstDayChange(value)}
                  clearIcon={null}
                />
              </div>
            </Form.Row>
            <Form.Row className={isDay1Selected ? '' :
               Style.hideElement}>
              <div className={Style.fieldContainer}
                style={{'marginRight': '15px'}}>
                <Form.Label className={Style.fieldLabel}>
                  Day 1
                </Form.Label>
                <Form.Control
                  readOnly={true}
                  defaultValue={weekDay1}
                  value={day1?.format('dddd')}
                >
                </Form.Control>
              </div>
              <div className={Style.fieldContainer}>
                <Form.Label className={Style.fieldLabel}>
                  Time
                </Form.Label>
                <Form.Control as="select" className={Style.shDropdown} custom
                  onChange={(e) => handleFirstHourChange(e)}
                  placeholder='Time1'
                >
                  <option value=''></option>
                  {
                    timeArray.map((time, index)=>(
                      <option value={time} key={index}>
                        {time}
                      </option>
                    ))
                  }
                </Form.Control>
              </div>
            </Form.Row>
            <Form.Row className={isDay1Selected ? Style.mb0:
              Style.hideElement}>
              <div className={Style.fieldContainer}
                style={{'marginRight': '15px'}}>
                <Form.Label className={Style.fieldLabel}>
                  Day 2
                </Form.Label>
                <Form.Control as="select" custom className={Style.shDropdown}
                  onChange={(e) => handleSecondDayChange(e)}
                  placeholder='Day2'
                >
                  <option value={''}></option>
                  {
                    dayArray.map((day, index)=>(
                      <option value={day} key={index}>
                        {day}
                      </option>
                    ))
                  }
                </Form.Control>
              </div>
              <div className={Style.fieldContainer}>
                <Form.Label className={Style.fieldLabel}>
                  Time
                </Form.Label>
                <Form.Control className={Style.shDropdown} as="select" custom
                  onChange={(e) => handleSecondHourChange(e)}
                  placeholder='Time2'
                >
                  <option value=''></option>
                  {
                    timeArray.map((time, index)=>(
                      <option value={time} key={index}>
                        {time}
                      </option>
                    ))
                  }
                </Form.Control>
              </div>
            </Form.Row>
            <div className={isDay1Selected ? Style.btnSpinnerWrapper :
            Style.hideElement}>
              { showCommonError ?
                  <Col className={`${Style.textCenter}
                   ${Style.textDanger} ${Style.mt3}`}>
                  Something went wrong.
                  </Col> : null
              }
              { showError ?
                <Col className={`${Style.textDanger} ${Style.textCenter}`}>
                  Second class should have minimum 2
                  hours difference if choosen same day.
                </Col> : null
              }
              <div className={Style.btnSpinnerWrapper}>
                <Button className={showButton ?
                Style.shBtn : Style.disabled}
                onClick={() => handleConfirmation()}>
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

RescheduleModal.propTypes = {
  modalShow: PropTypes.bool,
  closeHandler: PropTypes.func,
  modalTitle: PropTypes.string,
  regId: PropTypes.string,
  leadId: PropTypes.string,
  lessonsForCourse: PropTypes.array,
  setShowSpinner: PropTypes.func,
  getRegisteredCourses: PropTypes.func,
  setSuccessMessage: PropTypes.func,
  setFailureMessage: PropTypes.func,
  timeZone: PropTypes.string,
  slug: PropTypes.string,
};
