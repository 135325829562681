import React, {useState, useEffect} from 'react';
import {
  ColumnFlexWrapper,
  DisplayLayoutContainer,
  Button,
  Input,
} from '../Application/Shared2/Agent/styled-components';
import * as apiService from '../api';
import Subscriptions from
  '../Application/Shared2/Agent/Subscriptions/Subscriptions';
import BasicInfo from '../Application/Shared2/Agent/BasicInfo/BasicInfo';
import AddonInfo from '../Application/Shared2/Agent/AddonInfo/AddonInfo';
import SubscriptionActions from
  '../Application/Shared2/Agent/Subscriptions/SubscriptionActions';
import AddNewCourse from
  '../Application/Shared2/Agent/AddNewCourse/AddNewCourse';
import {
  RegisteredCoursesDetails,
} from
  '../Application/Shared2/Agent/RegisteredCourses/RegisteredCoursesDetails';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import '../Application/Shared2/Agent/index.module.scss';
import brandEnglishCourses from '../../data/brandEnglishCourses.json';
import brandNonEnglishCourses from '../../data/brandNonEnglishCourses.json';
import {navigate} from 'gatsby';
import styles from '../CsAgentOld/index.module.scss';
import * as trackAction from '../Application/Shared/TrackActionService';
import amplitude from 'amplitude-js';

const EmailSearch = ({locale}) => {
  const otherCourses = brandNonEnglishCourses?.nodes?.filter((ele) => {
    return (ele.publish_details.locale !== 'en-gb' &&
    ele.publish_details.locale !== 'en-us');
  });
  const otherLocales = {
    nodes: otherCourses,
  };
  otherLocales.nodes.push(brandEnglishCourses.nodes[0]);
  const courseData = getDataToPass(otherLocales);
  const textInput = React.createRef();
  const cancelSpinner = React.createRef();
  const [userData, setUserData] = useState(null);
  const [hasMount, triggerMount] = useState(false);
  const [showAlert, setShowAlert] =useState(false);
  const [studentSubcriptions, setStudentSubcriptions] = useState(null);
  const [membershipData, setMemberShipData] = useState(null);
  const [studentRegistrations, setStudentRegistrations] = useState(null);
  const [isRegistrationSpinner, setRegistrationSpinner] = useState(null);
  let studentsInfo=[];
  const handleEnterKey = (e) =>{
    if (e.charCode === 13) {
      searchEmail();
      trackAction.ctaClickAction({
        'cta': 'Email Search',
        'action': 'EmailSearched',
        'pageKind': 'CsAgentDashboard',
        'agentName': cookie.load('agent_name'),
      });
    }
  };
  useEffect(()=>{
    triggerMount(true);
    if (!cookie.load('auth_token') && !cookie.load('agent_email')) {
      navigate(`/cs-agent/login`);
    }
  }, []);
  const getRegisteredCoursesWithModules = (studentId) =>{
    studentsInfo=[];
    setRegistrationSpinner(true);
    new apiService.default.Registrations(studentId).list()
        .then((res) => (res.data)).then((res) => {
          studentsInfo = res;
          studentsInfo.data.map((studentData)=>{
            studentsInfo.included.map((studentIncludedData)=>{
              studentData.relationships?.modules?.data?.
                  map((moduleData)=>{
                    if (moduleData.id === studentIncludedData.id) {
                      moduleData['includedData']=studentIncludedData;
                    }
                  });
            });
          });
          setRegistrationSpinner(false);
          setStudentRegistrations(studentsInfo.data);
        })
        .catch((err) => {
          setRegistrationSpinner(false);
        });
  };

  const searchEmail = () => {
    const userEmail = textInput.current.value;
    setStudentRegistrations(null);
    new apiService.default.CsAgentStudentDetail(userEmail).studentDetails()
        .then((res) => {
          setUserData({
            'userInfo': {
              'Student Id': res?.data?.data?.id,
              'FirstName': res?.data?.data?.attributes?.name?.first_name,
              'LastName': res?.data?.data?.attributes.name?.last_name,
              'Email': res?.data?.data?.attributes?.email,
              'Phone No': res?.data?.data?.attributes?.phone,
              'Country': res?.data?.data?.attributes?.address?.country,
              'Language': res?.data?.data?.attributes?.language_id,
              'Age': res?.data?.data?.attributes?.profile?.age,
              'Gender': res?.data?.data?.attributes?.profile?.gender,
              'Goals': res?.data?.data?.attributes?.profile?.goals,
              'Status': res?.data?.data?.attributes?.membership?.status,
              'Slug': res?.data?.data?.attributes?.membership?.slug,
              'UTM Source': res?.data?.data?.attributes?.profile?.utm_source ||
              '-',
              'UTM Medium': res?.data?.data?.attributes?.profile?.utm_medium ||
              '-',
            },
            'userId': res?.data?.data?.id,
            'timeZone': res?.data?.data?.attributes?.timezone,
          });
          getAllSubscriptionsForStudent(res.data.data.id);
          setMemberShipData(res.data.data.attributes.membership);
          setShowAlert(false);
          if (res.data.data.id) {
            getRegisteredCoursesWithModules(res.data.data.id);
          }
          if (amplitude && amplitude.getInstance()) {
            amplitude.getInstance().setUserId(res?.data?.data?.id);
          }
        })
        .catch((err) => {
          setUserData(null);
          setShowAlert(true);
          setTimeout(()=>{
            setShowAlert(false);
          }, 3000);
        });
  };
  /**
   *
   *
   * @param {*} data
   * @return {*} arrayOfCOurses
   */
  function getDataToPass(data) {
    const arrayToReturn = [];
    for (const node of data.nodes) {
      if (node) {
        for (const item of node.coursecollections) {
          for (const course of item.courses) {
            arrayToReturn.push(course);
          }
        }
      }
    }
    return arrayToReturn;
  };
  const getAllSubscriptionsForStudent = (studentId) => {
    cancelSpinner.current ? cancelSpinner.current.style.display = 'none' : null;
    new apiService.default.CsAgentSubscriptions(studentId).getAllSubcriptions()
        .then((res) => {
          setStudentSubcriptions(res.data.data);
        })
        .catch((err) => {
          setStudentSubcriptions(null);
        });
  };
  return (
    <>
      {hasMount &&
    <ColumnFlexWrapper>
      <div className={showAlert ? styles.showAlert : styles.hideAlert}>
        User Not found.Please try another email
      </div>
      <DisplayLayoutContainer width30>
        <Input type="email" placeholder="Please enter email"
          ref={textInput} onKeyPress={(e)=>handleEnterKey(e)}/>
        <Button onClick={searchEmail}>Submit</Button>
      </DisplayLayoutContainer>
      {
        userData &&
        <AddNewCourse
          title={'Recover Course'}
          buttonName={'Recover Course'}
          userData={userData}
          allCourses={courseData}
          getRegisteredCourses = {getRegisteredCoursesWithModules}
          studentRegistrations={studentRegistrations}
          csAgent
        />
      }
      {
        userData &&
        <BasicInfo
          userData={userData}
          studentSubcriptions={studentSubcriptions}
          cancelSpinner={cancelSpinner}
          getAllSubscriptionsForStudent = {getAllSubscriptionsForStudent}
          getRegisteredCourses = {getRegisteredCoursesWithModules}
          csAgent
        />
      }
      {
        userData &&
        <SubscriptionActions
          userData={userData}
          studentSubcriptions={studentSubcriptions}
          searchEmail={searchEmail}
          cancelSpinner={cancelSpinner}
          csAgent
        />
      }
      {
          userData ?
          <Subscriptions
            userData={userData}
            studentSubcriptions={studentSubcriptions}
            getAllSubscriptionsForStudent={getAllSubscriptionsForStudent}
            cancelSpinner={cancelSpinner}
            searchEmail={searchEmail}
            setStudentSubcriptions={setStudentSubcriptions}
            membershipData={membershipData}
            csAgent
          /> : null
      }
      {
        userData &&
        <RegisteredCoursesDetails
          studentRegistrations={studentRegistrations}
          isLoading={isRegistrationSpinner}
          getRegisteredCourses={getRegisteredCoursesWithModules}
          studentId={userData.userId}
          timeZone={userData?.timeZone}
          userData={userData}
          csAgent
        />
      }
      {
        userData &&
        <AddonInfo userData={userData}/>
      }
    </ColumnFlexWrapper>}
    </>
  );
};
export default EmailSearch;
EmailSearch.propTypes = {
  locale: PropTypes.string,
};
