import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Spinner} from 'react-bootstrap';
import Helmet from 'react-helmet';
import {
  PRE_AUTH_ADDON_PREFIX, PRE_AUTH_ADDON_VERSION,
} from '../../../../Payments/Data/Prices/pre-auth';
import {ModalPopUp} from '../ModalPopUp';
import {CheckoutModal} from './CheckoutModal';
import {
  DisplayLayoutContainer,
  ChildDivDisplay,
  FlexContainer,
  Title,
  KeyStyle,
  Button,
  TitleContainer,
  ChildDivContainer,
} from '../styled-components';
import * as apiService from '../../../../api';
import * as trackAction from '../../../Shared/TrackActionService';
import cookie from 'react-cookies';
import * as myAccountService from '../../../Account/MyAccountService';
import styles from './index.module.scss';
import * as constant from '../../../../containers/Constants';

const Subscriptions = ({userData,
  studentSubcriptions, searchEmail,
  getAllSubscriptionsForStudent,
  cancelSpinner, membershipData,
  reactivationAgent, salesAgent, csAgent, susAgent}) =>{
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [userSubInfo, setUserSubInfo] = useState(null);
  const [displayCancelMessage, setDisplayCancelMessage] = useState(false);
  const [userSubscriptionData, setUserSubscriptionData] = useState(null);
  const [successfullyCancelled, setSuccessfullyCancelled] = useState(false);
  const [membershipPaused, setMembershipPaused] = useState(false);
  const [enablePauseCta, setEnablePauseCta] = useState(false);
  const [showResumeAlert, setShowResumeAlert] = useState(false);
  const [showPauseAlert, setShowPauseAlert] = useState(false);
  const [resumeDate, setResumeDate] = useState('');
  const [showMessage, setMessage] = useState(null);
  const [checkoutPopup, setCheckoutPopup] = useState(false);
  const paymentString='/payment/subscription/?product_code=sc-1m-ft&lead_id=';
  const leadId = userData?.userInfo['Student Id'];
  const [paymentLink, setPaymentLink] = useState('');

  const billingAt = userSubscriptionData?.next_billing_at ?
      new Date(userSubscriptionData?.next_billing_at * 1000):
      `${'NA'}`;

  const cancelledAt = userSubscriptionData?.cancelled_at &&
        userSubscriptionData?.cancelled_at !== 0 ?
        new Date(userSubscriptionData?.cancelled_at * 1000):
        `${'NA'}`;

  let createdAt = 'NA';
  if (userSubscriptionData?.created_at &&
      userSubscriptionData?.created_at !== 0) {
    createdAt = new Date(userSubscriptionData?.created_at * 1000);
  } else if (userSubscriptionData?.latest_receipt_info?.purchase_date_ms) {
    createdAt = new Date(
        parseInt(userSubscriptionData?.latest_receipt_info?.purchase_date_ms));
  }

  const billingAmount = userSubscriptionData?.currency_code &&
  userSubscriptionData?.plan_amount ?
  userSubscriptionData?.currency_code + ' ' +
  (userSubscriptionData?.plan_amount/100)
      .toFixed(2) :
            'NA';
  const agentName = cookie.load('agent_name');

  useEffect(() => {
    if (studentSubcriptions?.attributes?.default) {
      setUserSubscriptionData(studentSubcriptions?.attributes?.default
          ?.additional_platform_data);
    } else if (studentSubcriptions?.attributes?.chargebee) {
      setUserSubscriptionData(studentSubcriptions?.attributes?.chargebee);
    } else if (studentSubcriptions?.attributes?.itunes) {
      setUserSubscriptionData(studentSubcriptions?.attributes?.itunes);
    }
    if (studentSubcriptions && membershipData?.status === 'paused') {
      setMembershipPaused(true);
      const resumeDate =
      new Date(userSubscriptionData?.resume_date * 1000);
      setResumeDate(resumeDate);
    }
    studentSubcriptions &&
    setTimeout(() => {
      setDisplayCancelMessage(false);
      setSuccessfullyCancelled(false);
    }, 2000);
  }, [studentSubcriptions && studentSubcriptions.id,
    displayCancelMessage,
    successfullyCancelled]);

  useEffect(()=>{
    if (showMessage) {
      setTimeout(()=>{
        setMessage(null);
      }, 2000);
    }
  }, [showMessage]);

  useEffect(()=>{
    window.addEventListener('message', (event) => {
      if (event?.data === 'subscription_activated') {
        setCheckoutPopup(false);
        setMessage('Successful');
        // setShowEnableReactivationButton(false);
      }
    });
  }, []);

  useEffect(() => {
    if (studentSubcriptions?.attributes?.default?.platform === 'chargebee') {
      setUserSubInfo({
        platForm: 'Chargebee',
        id: studentSubcriptions?.id,
      });
    } else if (studentSubcriptions?.attributes?.default?.platform ===
      'epd') {
      setUserSubInfo({
        platForm: 'EPD',
        id: studentSubcriptions?.id,
      });
    } else if (studentSubcriptions?.attributes?.default?.platform ===
      'payfast') {
      setUserSubInfo({
        platForm: 'Payfast',
        id: studentSubcriptions?.id,
      });
    } else if (studentSubcriptions?.attributes?.itunes) {
      setUserSubInfo({
        platForm: 'iTunes',
        id: studentSubcriptions?.id,
      });
    }
  }, [studentSubcriptions]);

  const cancelSubscription = () => {
    let pageKind = 'CsAgentDashboard';
    if (salesAgent) {
      pageKind = 'SalesAgentDashboard';
    } else if (reactivationAgent) {
      pageKind = 'ReactivationAgentDashboard';
    }
    trackAction.ctaClickAction({
      'studentId': userData.userId,
      'cta': 'Cancel Membership',
      'action': 'CancelMembership',
      pageKind,
      'agentName': agentName,
    });
    cancelSpinner.current.style.display = 'block';
    setDisplayCancelMessage(false);
    setSuccessfullyCancelled(false);
    let subId = studentSubcriptions?.id;
    if (studentSubcriptions?.attributes?.default) {
      subId = studentSubcriptions.attributes.default?.
          additional_platform_data?.id;
    }
    const studentId = userData?.userId;
    const requestObj = {};
    requestObj['lead_id'] = studentId;
    requestObj['cancellation_reason'] = 'Subscription cancelled by CS agent';

    new apiService.default.CsAgentSubscriptions(studentId, subId)
        .cancelSubscription(requestObj)
        .then((res) => {
          setTimeout(() => getAllSubscriptionsForStudent(studentId), 13000);
          setTimeout(() => {
            setDisplayCancelMessage(true);
            setSuccessfullyCancelled(true);
          }, 12500);
        })
        .catch((err) => {
          setDisplayCancelMessage(true);
          setSuccessfullyCancelled(false);
          cancelSpinner.current.style.display = 'none';
        });
  };

  const pauseMembership = () => {
    let pageKind = 'CsAgentDashboard';
    if (salesAgent) {
      pageKind = 'SalesAgentDashboard';
    } else if (reactivationAgent) {
      pageKind = 'ReactivationAgentDashboard';
    }
    trackAction.ctaClickAction({
      'studentId': userData.userId,
      'cta': 'Pause Membership',
      'action': 'PauseMembership',
      pageKind,
      'agentName': agentName,
    });
    const studentId = userData?.userId;
    const currentDate = new Date();
    const resumeOn = new Date(currentDate
        .setDate(currentDate.getDate() + 30))
        .toISOString();
    const subId = studentSubcriptions?.id;
    const planId = userSubscriptionData?.plan_id;
    const requestData = {
      student_id: studentId,
      resume_on: resumeOn,
    };

    new apiService.Subscription(subId)
        .pauseSubscription(requestData)
        .then((response) => {
          let pageKind = 'CsAgentDashboard';
          if (salesAgent) {
            pageKind = 'SalesAgentDashboard';
          } else if (reactivationAgent) {
            pageKind = 'ReactivationAgentDashboard';
          }
          window.dataLayer.push({
            'event': 'subscription-change-action',
            'subscription-change-param': {
              'studentId': studentId,
              'action': 'Paused',
              pageKind,
              'planId': planId || '',
            },
          });
          setMembershipPaused(true);
          setShowPauseAlert(true);
          setResumeDate(new Date(resumeOn));
          setTimeout(() => {
            setShowPauseAlert(false);
          }, 5000);
        })
        .catch((error) => {
          console.log(error);
        });
  };

  const resumeMembership = () => {
    const studentId = userData?.userId;
    const subId = studentSubcriptions.id;
    const planId = userSubscriptionData?.plan_id;
    const requestObj = {
      student_id: studentId,
    };
    new apiService.default.Subscription(subId)
        .resumeMembership(requestObj)
        .then((response) => {
          let pageKind = 'CsAgentDashboard';
          if (salesAgent) {
            pageKind = 'SalesAgentDashboard';
          } else if (reactivationAgent) {
            pageKind = 'ReactivationAgentDashboard';
          }
          trackAction.ctaClickAction({
            'cta': 'Resume Membership',
            'action': 'ResumeMembership',
            pageKind,
            'agentName': agentName,
            'planId': planId || '',
          });
          setMembershipPaused(false);
          setEnablePauseCta(false);
          setShowResumeAlert(true);
          setTimeout(() => {
            setShowResumeAlert(false);
          }, 5000);
        })
        .catch((error) => {
          console.log(error);
        });
  };

  /**
   * reactivate membership
   *
   * @memberof Subscription
   */
  const reactivateMembership = () => {
    let pageKind = 'CsAgentDashboard';
    if (salesAgent) {
      pageKind = 'SalesAgentDashboard';
    } else if (reactivationAgent) {
      pageKind = 'ReactivationAgentDashboard';
    }
    trackAction.ctaClickAction({
      'studentId': userData.userId,
      'cta': 'Reactivate',
      'action': 'Reactivate',
      pageKind,
      'agentName': agentName,
    });
    cancelSpinner.current.style.display = 'block';
    myAccountService.getStudentInfoIncluded(userData?.userId)
        .then((response) => {
          let subsInfo = response?.subscriptionInfo?.attributes?.chargebee;
          if (response?.subscriptionInfo?.attributes?.default) {
            subsInfo = response?.subscriptionInfo?.attributes?.default
                ?.additional_platform_data;
          }

          if (subsInfo) {
            const requestObj = {};
            requestObj['student_id'] = userData?.userId;
            requestObj['plan_id'] = subsInfo?.plan_id;

            // if (subsInfo.attributes.chargebee.status === 'active') {
            //   requestObj['term_end'] = subsInfo.attributes.
            //       chargebee.current_term_end;
            // }
            if (subsInfo?.status === 'in_trial') {
              // requestObj['trial_end'] = subsInfo?.attributes.
              //     chargebee.trial_end;
              requestObj['trial_end'] = subsInfo?.trial_end;
            }
            if (subsInfo?.coupon) {
              requestObj['coupon_ids'] =
                  [subsInfo?.coupon];
            }
            new apiService.Subscription(subsInfo.id)
                .reactivateWithTrial(userData?.userId)
                .then((response) => {
                  trackAction.subscriptionChangeAction({
                    planId: requestObj['plan_id'],
                    action: 'Reactivated',
                  });
                  setMessage('Successful');
                  cancelSpinner.current.style.display = 'none';
                })
                .catch((error) => {
                  setMessage('Update subscription failed');
                  console.log(error);
                  cancelSpinner.current.style.display = 'none';
                  openRespectivePages();
                });
          }
        })
        .catch((error) => {
          setMessage('Update subscription failed');
          console.log(error);
          cancelSpinner.current.style.display = 'none';
          openRespectivePages();
        });
  };

  const extendMembership = (param) =>{
    let pageKind = 'CsAgentDashboard';
    if (salesAgent) {
      pageKind = 'SalesAgentDashboard';
    } else if (reactivationAgent) {
      pageKind = 'ReactivationAgentDashboard';
    }
    trackAction.ctaClickAction({
      'studentId': userData.userId,
      'cta': 'Extend Membership',
      'action': 'ExtendMembership',
      pageKind,
      'agentName': agentName,
    });
    cancelSpinner.current.style.display = 'block';
    myAccountService.getStudentInfoIncluded(userData?.userId)
        .then((response) => {
          let subsInfo = response?.subscriptionInfo?.attributes?.chargebee;
          if (response?.subscriptionInfo?.attributes?.default) {
            subsInfo = response?.subscriptionInfo?.attributes?.default
                ?.additional_platform_data;
          }

          if (subsInfo) {
            const subscriptionInfo = subsInfo;
            const subId = response?.subscriptionInfo?.id;
            const subsData = subscriptionInfo;
            const subscriptionStatus = subsInfo.status;
            let subDate;
            if (subscriptionStatus === 'in_trial') {
              subDate = new Date(subsData['trial_end'] * 1000);
            } else {
              subDate = new Date(subsData['current_term_end'] * 1000);
            }
            if (new Date(subDate).getTime() < new Date().getTime()) {
              subDate = new Date();
            }
            let daysToAdd = 7;
            if (param) {
              daysToAdd = 7 * param * 4;
            }
            const requestDate = new Date(subDate
                .setDate(subDate.getDate() + daysToAdd)).toISOString();
            const requestObj = {};
            const planId = subsInfo['plan_id'];
            const couponIds = subsInfo['coupon'];
            requestObj['student_id'] = userData?.userId;
            if (subscriptionStatus === 'in_trial') {
              requestObj['trial_end'] = requestDate;
            } else {
              requestObj['term_end'] = requestDate;
            }
            requestObj['plan_id'] = planId;
            if (couponIds) {
              requestObj['coupon_ids'] = [couponIds];
            }
            new apiService.Subscription(subId)
                .updateSubscription(requestObj)
                .then((response) => {
                  cancelSpinner.current.style.display = 'none';
                  setMessage('Extension Successful');
                })
                .catch((err) => {
                  setMessage('updating subscription failed');
                  console.log(err);
                  cancelSpinner.current.style.display = 'none';
                });
          }
        })
        .catch((error) => {
          setMessage('Get student info failed');
          console.log(error);
          cancelSpinner.current.style.display = 'none';
        });
  };

  /**
   *
   *
   * @param {*} oneClickPreauthParams
   */
  const oneClickPurchase = async ({studentId, product}) => {
    cancelSpinner.current.style.display = 'block';
    const addonId = PRE_AUTH_ADDON_PREFIX + '_' +
    PRE_AUTH_ADDON_VERSION + '_' +
    product.billingCountry;
    const data = {
      student_id: studentId,
      addons: [{
        id: addonId,
        quantity: 1,
        allow_duplicate: true,
      }],
      currency_code: product.currency,
    };
    new apiService.default.ReactivationAgent().invoiceAddons(data)
        .then((response)=>{
          setMessage('Successful');
          cancelSpinner.current.style.display = 'none';
        })
        .catch((error)=>{
          setMessage('oneclick purchase failed');
          console.log(error);
          cancelSpinner.current.style.display = 'none';
        });
  };

  /**
   * update card
   *
   */
  const updateCard = () => {
    let pageKind = 'CsAgentDashboard';
    if (salesAgent) {
      pageKind = 'SalesAgentDashboard';
    } else if (reactivationAgent) {
      pageKind = 'ReactivationAgentDashboard';
    }
    trackAction.ctaClickAction({
      'studentId': userData.userId,
      'cta': 'Update Card',
      'action': 'UpdateCard',
      pageKind,
      'agentName': agentName,
    });
    openRespectivePages();
    setCheckoutPopup(true);
  };

  const openRespectivePages = () => {
    if (userData?.userInfo?.Status === 'in_trial') {
      const url = `${process.env.PaymentWebDomain}${paymentString}${leadId}`;
      setPaymentLink(url);
    } else if (userData?.userInfo?.Status === 'inactive') {
      const url = `${process.env.PaymentWebDomain}${paymentString}${leadId}`;
      setPaymentLink(url);
    } else {
      let frequency = 'monthly';
      if (studentSubcriptions?.attributes?.chargebee) {
        const subInfo = studentSubcriptions?.attributes?.chargebee;
        if (subInfo?.billing_period === 3) {
          frequency = 'quarterly';
        } else if (subInfo?.billing_period === 6) {
          frequency = 'biannual';
        } else if (subInfo?.billing_period === 12) {
          frequency = 'annual';
        }
      } else if (studentSubcriptions?.attributes?.default) {
        const subInfo = studentSubcriptions?.attributes?.default?.
            additional_platform_data;
        if (subInfo?.billing_period === 3) {
          frequency = 'quarterly';
        } else if (subInfo?.billing_period === 6) {
          frequency = 'biannual';
        } else if (subInfo?.billing_period === 12) {
          frequency = 'annual';
        }
      }
      const domain = `${process.env.PaymentWebDomain}/payment/subscription/`;
      setPaymentLink(`${domain}${frequency}/?lead_id=${userData?.userId}`);
    }
  };

  const openPayment = (gateway, country) => {
    trackAction.ctaClickAction({
      'studentId': userData?.userId,
      'cta': 'Add Subscription',
      'action': 'AddSubscription',
      'pageKind': 'SusAgentDashboard',
      'agentName': agentName,
    });

    const domain = process.env.PaymentWebDomain;
    if (country === 'za') {
      if (gateway === 'payfast') {
        const url = `${domain}/za${paymentString}${leadId}&loginoverride=true`;
        setPaymentLink(url);
      } else if (gateway === 'bluesnap') {
        const id = process.env.bluesnapUSChargebeeGatewayId;
        const param1 = `/payment/subscription/?product_code=sc-1m-ft`;
        const param2 = `&chargebee_gateway_id=${id}&lead_id=`;
        const url = `${domain}${param1}${param2}${leadId}&loginoverride=true`;
        setPaymentLink(url);
      } else if (gateway === 'checkout') {
        const id = process.env.checkoutDefaultChargebeeGatewayId;
        const param1 = `/payment/subscription/?product_code=sc-1m-ft`;
        const param2 = `&chargebee_gateway_id=${id}&lead_id=`;
        const url = `${domain}${param1}${param2}${leadId}&loginoverride=true`;
        setPaymentLink(url);
      }
    } else if (country === 'us') {
      if (gateway === 'epd') {
        const id = process.env.epdUSGatewayId;
        const param1 = `/payment/subscription/?product_code=sc-1m-ft`;
        const param2 = `&chargebee_gateway_id=${id}&lead_id=`;
        const url = `${domain}${param1}${param2}${leadId}&loginoverride=true`;
        setPaymentLink(url);
      } else if (gateway === 'bluesnap') {
        const id = process.env.bluesnapUSChargebeeGatewayId;
        const param1 = `/payment/subscription/?product_code=sc-1m-ft`;
        const param2 = `&chargebee_gateway_id=${id}&lead_id=`;
        const url = `${domain}${param1}${param2}${leadId}&loginoverride=true`;
        setPaymentLink(url);
      } else if (gateway === 'checkout') {
        const id = process.env.checkoutDefaultChargebeeGatewayId;
        const param1 = `/payment/subscription/?product_code=sc-1m-ft`;
        const param2 = `&chargebee_gateway_id=${id}&lead_id=`;
        const url = `${domain}${param1}${param2}${leadId}&loginoverride=true`;
        setPaymentLink(url);
      }
    } else {
      const url = `${process.env.PaymentWebDomain}${paymentString}${leadId}`;
      setPaymentLink(url);
    }
    setCheckoutPopup(true);
  };
  /**
   * validate card
   *
   */
  const validateCard = () =>{
    let pageKind = 'CsAgentDashboard';
    if (salesAgent) {
      pageKind = 'SalesAgentDashboard';
    } else if (reactivationAgent) {
      pageKind = 'ReactivationAgentDashboard';
    } else if (susAgent) {
      pageKind = 'SusAgentDashboard';
    }
    trackAction.ctaClickAction({
      'studentId': userData.userId,
      'cta': 'Validate Card',
      'action': 'ValidateCard',
      pageKind,
      'agentName': agentName,
    });
    cancelSpinner.current.style.display = 'block';
    myAccountService.getStudentInfoIncluded(userData?.userId)
        .then((response) => {
          let billingCountry = userData.userInfo['Country'];
          const euCountry = constant.euCountryList;
          const usCountry = constant.lowerUSDCountryList;
          if (usCountry.includes(billingCountry)) {
            billingCountry = '3w';
          }
          if (euCountry.includes(billingCountry)) {
            billingCountry = 'eu';
          }
          const product = {
            billingCountry,
            currency: subsInfo?.currency_code,
          };
          const oneClickPreauthParams = {
            studentId: userData.userId,
            product,
          };
          oneClickPurchase(oneClickPreauthParams);
        })
        .catch((error)=>{
          setMessage('subscriptionInfo failed');
          console.log(error);
          cancelSpinner.current.style.display = 'none';
        });
  };

  return (
    <>
      <Helmet>
        <script src="https://js.chargebee.com/v2/chargebee.js"></script>
      </Helmet>
      {
        showMessage &&
        <div className={
          showMessage === 'Successful' ?
          `${styles.showAlert} ${styles.success}`:
          `${styles.showAlert} ${styles.fail}`
        }>
          {
            showMessage
          }
        </div>
      }
      <DisplayLayoutContainer>
        <TitleContainer>
          <Title>
            Subscription
            ({userSubInfo?.platForm} - {userSubInfo?.id})
          </Title>
          <Button
            onClick={searchEmail}
            style={{'marginBottom': '8px'}}
          >
            Refresh
          </Button>
          <div style={{
            'display': 'flex',
            'justifyContent': 'flex-end',
            'alignItems': 'center',
          }}>
            { studentSubcriptions &&
              !membershipPaused ? <ChildDivDisplay
                style={{'backgroundColor': '#ffffff', 'marginRight': '10px'}}
              >
                { showResumeAlert && <div style={{
                  color: 'green',
                  fontSize: '12px',
                  margin: '10px 10px 0px 20px'}}
                >
                    Membership Resumed Successfully.
                </div>}
              </ChildDivDisplay > : null}
            { membershipPaused &&
              !reactivationAgent ? <ChildDivDisplay >
                <Button
                  onClick={resumeMembership}
                  margin0
                  style={{'marginRight': '10px'}}
                >
                    Resume Now
                </Button>
                { showPauseAlert ? <div style={{
                  color: 'red', fontSize: '12px', margin: '10px 10px 0px 20px'}}
                >
                    Membership Paused Successfully.
                </div> : <div style={{
                  fontSize: '12px', margin: '10px 10px 0px 20px'}}
                >
                  Resuming On : {resumeDate && resumeDate.toDateString()}
                </div>}
              </ChildDivDisplay> :
            null
            }
            { studentSubcriptions ?
            membershipData?.slug === 'lifetime' ?
              <div style={{fontWeight: '600',
                color: 'green',
                marginRight: '10px',
              }}>
                Lifetime Membership
              </div> :
              <TitleContainer>
                {
                  displayCancelMessage ?
                  successfullyCancelled ?
                  <div style={{
                    color: 'green', fontSize: '12px',
                    margin: '10px 10px 0px 0px'}}
                  >
                    Successfully cancelled.
                  </div> :
                  <div style={{
                    color: 'red',
                    fontSize: '12px',
                    margin: '10px 10px 0px 0px'}}
                  >
                    Failed.
                  </div> : null
                }
                {/* <Button onClick={()=>setShowCancelModal(true)}
                  disabled={cancelledAt === 'NA' ? false : true}
                  style={cancelledAt === 'NA' ? {} : {cursor: 'not-allowed'}}
                >Cancel</Button> */}
              </TitleContainer> : null
            }
            <div
              style={{margin: '10px 10px 0px 0px', display: 'none'}}
              ref={cancelSpinner}
            >
              <Spinner animation="border" style={{marginRight: '10px'}}/>
            </div>
          </div>
        </TitleContainer>
        <hr/>
        {
            studentSubcriptions ?
            <>
              <ChildDivContainer>
                <ChildDivDisplay>
                  <FlexContainer>
                    <KeyStyle>Plan Duration: </KeyStyle>
                    <div>
                      {
                      userSubscriptionData?.
                          billing_period ?
                      `${userSubscriptionData?.
                          billing_period}
          ${userSubscriptionData?.
                          billing_period_unit}` : 'NA'
                      }
                    </div>
                  </FlexContainer>

                  <FlexContainer>
                    <KeyStyle>Next Billing At: </KeyStyle>
                    <div>
                      {
                      billingAt === 'NA' ?
                      billingAt : billingAt.toString().substring(4, 16)
                      }
                    </div>
                  </FlexContainer>
                </ChildDivDisplay>
                <ChildDivDisplay>
                  <FlexContainer>
                    <KeyStyle>Billing Amount: </KeyStyle>
                    <div>
                      {
                        billingAmount
                      }
                    </div>
                  </FlexContainer>
                  <FlexContainer>
                    <KeyStyle>Scheduled Cancelled On: </KeyStyle>
                    <div>
                      {
                      cancelledAt === 'NA' ?
                      cancelledAt : cancelledAt.toString().substring(4, 16)
                      }
                    </div>
                  </FlexContainer>
                </ChildDivDisplay>
                <ChildDivDisplay>
                  <FlexContainer>
                    <KeyStyle>Created At: </KeyStyle>
                    <div>
                      {
                        createdAt === 'NA' ?
                        createdAt : createdAt.toString().substring(4, 16)
                      }
                    </div>
                  </FlexContainer>
                </ChildDivDisplay>
              </ChildDivContainer>
              { userSubscriptionData?.has_scheduled_changes &&
                <div style={{
                  'margin': '10px 8px',
                  'textAlign': 'left',
                }}>
                  Scheduled Changes:
                  {userSubscriptionData?.scheduled_changes?.plan_id}
                </div>
              }
            </> :
            <FlexContainer><p>No subscription found.</p></FlexContainer>
        }

        <ModalPopUp
          modalShow={showCancelModal} modalTitle={'Confirm Cancellation'}
          modalBody={'Are you sure to cancel the subscription?'}
          closeHandler={()=>{
            setShowCancelModal(false);
            setDisplayCancelMessage(false);
          }}
          confirmHandler={()=>{
            cancelSubscription();
            setShowCancelModal(false);
          }}
        />
        <CheckoutModal
          modalShow={checkoutPopup}
          url=
            {paymentLink}
          closeHandler={()=>{
            setCheckoutPopup(false);
          }}
        />
        <div className={styles.ctaWrapper}>
          {
            ((reactivationAgent || susAgent) && userSubscriptionData) &&
            !studentSubcriptions?.attributes?.itunes &&
              <>
                {reactivationAgent && <Button
                  disabled={enablePauseCta}
                  style={{'cursor': 'pointer'}}
                  onClick={pauseMembership}
                >
                    Pause Membership
                </Button>}
                <Button
                  style={{
                    'cursor': 'pointer',
                    'marginRight': '10px',
                  }}
                  onClick={validateCard}
                >
                  Validate Card
                </Button>
                {reactivationAgent && <><Button
                  style={{
                    'cursor': 'pointer',
                    'marginRight': '10px',
                  }}
                  onClick={updateCard}
                >
                  Update Card
                </Button>
                <Button
                  style={{
                    'cursor': 'pointer',
                    'marginRight': '10px',
                  }}
                  onClick={reactivateMembership}
                >
                  Reactivate
                </Button></>}
                {/* {
                  ['inactive'].includes(membershipData?.status) &&
                  <Button
                    style={{
                      'cursor': 'pointer',
                      'marginRight': '10px',
                    }}
                  >
                    Activate Membership
                  </Button>
                } */}
              </>
          }
          {
            (susAgent && !userSubscriptionData &&
              userData?.userInfo?.Country === 'za') &&
              <>
                <Button
                  style={{
                    'cursor': 'pointer',
                    'marginRight': '10px',
                  }}
                  onClick={()=>openPayment('payfast', 'za')}
                >
                  Payfast
                </Button>
                <Button
                  style={{
                    'cursor': 'pointer',
                    'marginRight': '10px',
                  }}
                  onClick={()=>openPayment('bluesnap', 'za')}
                >
                  BlueSnap
                </Button>
              </>
          }
          {
            (susAgent && !userSubscriptionData &&
              userData?.userInfo?.Country === 'us') &&
              <>
                <Button
                  style={{
                    'cursor': 'pointer',
                    'marginRight': '10px',
                  }}
                  onClick={()=>openPayment('epd', 'us')}
                >
                  EPD
                </Button>
                <Button
                  style={{
                    'cursor': 'pointer',
                    'marginRight': '10px',
                  }}
                  onClick={()=>openPayment('bluesnap', 'us')}
                >
                  BlueSnap
                </Button>
              </>
          }
          { susAgent && !userSubscriptionData &&
          (userData?.userInfo?.Country !== 'za' &&
          userData?.userInfo?.Country !== 'us') &&
            <Button
              onClick={()=>openPayment('subscription', '')}
              style={{'marginBottom': '8px'}}
            >
            Add Subscription
            </Button>
          }
          <div className={studentSubcriptions?.attributes?.itunes ||
            csAgent || susAgent ? styles.hide : ''}>
            {membershipData?.slug !== 'lifetime'?(
              reactivationAgent?
              <Button
                style={{'cursor': 'pointer'}}
                onClick={() => extendMembership(1)}
              >
                    Extend Membership
              </Button>:
              <Button
                disabled={enablePauseCta}
                style={{'cursor': 'pointer'}}
                onClick={pauseMembership}
              >
                    Pause Membership
              </Button>):
              null
            }
          </div>
          <div className={studentSubcriptions?.attributes?.itunes ||
          csAgent || reactivationAgent || susAgent ?
            styles.hide : ''}>
            <Button
              style={{'cursor': 'pointer'}}
              onClick={()=>setShowCancelModal(true)}
            >
            Cancel Membership
            </Button>
          </div>
          <div className={studentSubcriptions?.attributes?.itunes ||
          salesAgent || reactivationAgent || susAgent ?
            styles.hide : ''}>
            <Button
              style={{'cursor': 'pointer'}}
              onClick={() => extendMembership(1)}
            >
            ExtendMembership(1M)
            </Button>
            <Button
              style={{'cursor': 'pointer'}}
              onClick={() => extendMembership(3)}
            >
            ExtendMembership(3M)
            </Button>
            <Button
              style={{'cursor': 'pointer'}}
              onClick={() => extendMembership(6)}
            >
            ExtendMembership(6M)
            </Button>
            <Button
              style={{'cursor': 'pointer'}}
              onClick={() => extendMembership(12)}
            >
            ExtendMembership(12M)
            </Button>
            <Button
              style={{'cursor': 'pointer'}}
              onClick={()=>setShowCancelModal(true)}
            >
            Cancel Membership
            </Button>
            <Button
              disabled={enablePauseCta}
              style={{'cursor': 'pointer'}}
              onClick={pauseMembership}
            >
                    Pause Membership
            </Button>
          </div>
        </div>
      </DisplayLayoutContainer>
    </>
  );
};

Subscriptions.propTypes = {
  studentSubcriptions: PropTypes.object,
  getAllSubscriptionsForStudent: PropTypes.func,
  cancelSpinner: PropTypes.any,
  membershipData: PropTypes.object,
  userData: PropTypes.object,
  reactivationAgent: PropTypes.bool,
  salesAgent: PropTypes.bool,
  searchEmail: PropTypes.func,
  csAgent: PropTypes.bool,
  susAgent: PropTypes.bool,
};

export default Subscriptions;
