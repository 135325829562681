import * as apiService from '../../../../api';
import * as CommonService from '../CommonService';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const addonPurchaseAction = (
    data, setShowSpinner, setSuccessMessage, setFailureMessage) => {
  const dataToApi = getDataToPass(data);
  const dataToPurchaseAddonApi=getPurchaseAddonData(data);
  buyAddon(dataToApi, data, dataToPurchaseAddonApi, setShowSpinner,
      setSuccessMessage, setFailureMessage);
};
/**
 *
 *
 * @param {*} data
 * @return {*} dataToPass
 */
function getDataToPass(data) {
  const tempArr = [];
  const tempObj = {
    id: `module-${data.addon}_${data.activeTierCode}_${data.country}`,
    eqf_slug: data.slug,
    module_number: data.moduleNumber,
    quantity: 1,
  };
  if (data.addon === 'hard-copy-diploma') {
    tempObj['id'] =
      `course-${data.addon}_${data.activeTierCode}_${data.country}`;
  }
  if (data.addon==='retake') {
    tempObj['id']= `module-assessment-retake_${
      data.activeTierCode}_${data.country}`;
    tempObj['allow_duplicate'] = true;
  }
  tempArr.push(tempObj);
  const postData = {
    student_id: data.studentId,
    currency_code: data.currencyCode,
    addons: tempArr,
    internal_credits_used: null,
    source_url: window.location.href,
  };
  return postData;
};

/**
 *
 *
 * @param {*} data
 * @return {*}
 */
function getPurchaseAddonData(data) {
  let id=
`module-${data.addon}_${data.activeTierCode}_${data.country}`;
  if (data.addon === 'hard-copy-diploma') {
    id =
    `course-${data.addon}_${data.activeTierCode}_${data.country}`;
  }
  let allowDuplicate=false;
  if (data.addon === 'retake') {
    id =
`module-assessment-retake_${data.activeTierCode}_${data.country}`;
    allowDuplicate=true;
  }

  return {
    student_id: data.studentId,
    line_items: [
      {
        item_id: id,
        item_type: 'addon',
        eqf_slug: data.slug,
        module_number: data.moduleNumber,
        allow_duplicate: allowDuplicate,
      },
    ],
    coupon_ids: [],
    currency_code: data.currencyCode,
  };
};

/**
 *
 *
 * @param {*} params1
 * @param {*} params2
 * @return {*} obj
 */
function getDataToOrdersApi(params1, params2) {
  const lineItems = [];
  const moduleNumbers = [1, 2, 3, 4];
  moduleNumbers.splice(moduleNumbers.indexOf(params2.moduleNumber), 1);
  for (let i = 0; i < moduleNumbers.length; i++) {
    let obj = {};
    obj = {
      item_id: `module-${params2.addon}`,
      item_type: 'addon',
      eqf_slug: params2.slug,
      module_number: moduleNumbers[i],
      allow_duplicate: true,
    };
    lineItems.push(obj);
  }
  return {
    student_id: params1.student_id,
    line_items: lineItems,
    gateway: {
      name: 'gift',
    },
  };
};

/**
 *
 *
 * @param {*} dataToApi
 * @param {*} dataToNavigate
 * @param {*} purchaseAddonPayload
 * @param {*} setShowSpinner
 * @param {*} setSuccessMessage
 * @param {*} setFailureMessage
 *
 */
function buyAddon(dataToApi,
    dataToNavigate, purchaseAddonPayload, setShowSpinner,
    setSuccessMessage, setFailureMessage,
) {
  let productToTrack = 'CourseToolkit';
  if (dataToNavigate.addon === 'cert') {
    productToTrack = 'CourseEcert';
  }
  if (dataToNavigate.addon === 'hard-copy-diploma') {
    productToTrack = 'HardCopyDiploma';
  }

  new apiService.default.Addons().purchaseAddon(purchaseAddonPayload)
      .then((response) => {
        window.dataLayer.push({
          'event': 'addon-purchase-action',
          'event-param': {
            'identity': dataToNavigate.studentId,
            'product': productToTrack,
            'courseSlug': dataToNavigate?.slug,
            'moduleNumber': dataToNavigate?.moduleNumber,
            'quantity': 1,
            'pageKind': dataToNavigate?.pageKind,
            'cta': decodeURI(dataToNavigate.cta),
          },
        });
        const dataToOrderApi = getDataToOrdersApi(dataToApi, dataToNavigate);
        new apiService.default.Addons().giftAddon(dataToOrderApi)
            .then((res) => {
              setShowSpinner(false);
              setSuccessMessage('Toolkit purchase successful');
            })
            .catch((err) => {
              console.log(err);
              setShowSpinner(false);
              setFailureMessage('giftAddon failed');
            });
      })
      .catch((error) => {
        console.log('purchase', error);
        setShowSpinner(false);
        setFailureMessage('purchase addon failed');
      });
};

/**
   *
   * get Schedule
   * @param {*} schedule
   * @return {*}
   */
export const getSchedule = (schedule) => {
  if (schedule) {
    const selectedWeekday = schedule.day();
    const today = dayjs().isoWeekday();
    let selectedDay;
    if (today < selectedWeekday) {
      selectedDay = dayjs().isoWeekday(selectedWeekday);
    } else {
      selectedDay = dayjs().add(7, 'days').isoWeekday(selectedWeekday);
    }
    return selectedDay
        .set('hour', schedule.hour())
        .set('minute', schedule.minute())
        .set('second', schedule.second());
  }
};

/**
   *
   *
   * @param {*} registrationObj
   * @param {*} studentId
   * @return {*}
   */
export const getRescheduleCourseObject = (registrationObj, studentId) =>{
  // let firstDayOfWeek;
  // let secondDayOfWeek;
  const lessons= registrationObj?.lessonsArray;

  const schedule1 = registrationObj?.schedule1;

  const schedule2 = registrationObj?.schedule2;
  // default value of Sunday = 0 in moment,
  // need to change it to Sunday = 7
  // if (schedule1.day() === 0) {
  //   firstDayOfWeek = 7;
  // } else {
  //   firstDayOfWeek = schedule1.day();
  // }
  // if (schedule2.day() === 0) {
  //   secondDayOfWeek = 7;
  // } else {
  //   secondDayOfWeek = schedule2.day();
  // }
  const {firstDayOfWeek, secondDayOfWeek} =
  CommonService.getDaysOfWeek(schedule1, schedule2);

  const requestObj = {
    'data': {
      'type': 'registrations',
      'id': registrationObj.regId,
      'attributes': {
        'schedule': {
          'first_day_of_week': firstDayOfWeek,
          'second_day_of_week': secondDayOfWeek,
          'first_day_hour': parseInt(schedule1.format('H')),
          'second_day_hour': parseInt(schedule2.format('H')),
          'lessons': lessons,
        },
      },
      'relationships': {
        'student': {
          'data': {
            'type': 'student',
            'id': studentId,
          },
        },
      },
    },
  };
  return requestObj;
};

/**
   *
   * build lesson array
   * @param {*} registerCourseObject
   * @return {*}
   *
   */
// export const buildLessonArray = (registerCourseObject) => {
//   const lessonLength =
//     Math.ceil(registerCourseObject.lessons?.length/2);
//   const lessonsArray=[];

//   for (let i = 0; i < lessonLength; i++) {
//     let day1 = registerCourseObject.schedule1.clone();
//     day1 = day1.add(i*7, 'days').toISOString();
//     let day2 = registerCourseObject.schedule2.clone();
//     day2 = day2.add(i*7, 'days').toISOString();
//     lessonsArray.push({
//       number: lessonsArray.length + 1,
//       start_time: day1,
//     });
//     lessonsArray.push({
//       number: lessonsArray.length + 1,
//       start_time: day2,
//     });
//   }
//   return lessonsArray;
// };
