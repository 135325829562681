import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Spinner} from 'react-bootstrap';
import {DisplayLayoutContainer,
  Button,
  Title,
  BasicInfoWrapper,
  FlexContainer,
  KeyStyle,
  RowFlexWrapper,
  TitleContainer,
} from '../styled-components';
import '../index.module.scss';
import {ModalPopUp} from '../ModalPopUp';
import {CheckoutModal} from '../Subscriptions/CheckoutModal';
import * as apiService from '../../../../api';
import * as trackAction from '../../../Shared/TrackActionService';
import cookie from 'react-cookies';

const BasicInfo = ({userData, studentSubcriptions,
  getAllSubscriptionsForStudent, getRegisteredCourses, csAgent}) => {
  const [showModal, setShowModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openLoginUser, setOpenLoginUser] = useState(false);
  const [iframeLink, setIframeLink] = useState('');
  const [failedAPICount, setFailedAPICount] = useState(0);
  const [coursesDeleted, setCoursesDeleted] = useState(0);
  const [coursesNotDeleted, setCoursesNotDeleted] = useState(0);
  const [subscriptionAPISuccess,
    setSubscriptionAPISuccess] = useState(false);
  const [studentAPISuccess,
    setStudentAPISuccess] = useState(false);
  const [registeredCoursesLen, setRegisteredCoursesLen] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [failureMessage, setFailureMessage] = useState(null);
  const [deleteFreshSales, setDeleteFreshSales] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => {
    trackAction.ctaClickAction({
      'studentId': userData?.userId,
      'agentName': cookie.load('agent_name'),
      'cta': 'Delete Account',
      'action': 'DeleteAccountInitiated',
      'pageKind': 'CsLoginDashboard',
    });
    setShowModal(true);
  };
  const cancelSubscriptionRequestObj = {};
  cancelSubscriptionRequestObj['lead_id'] = userData.userId;
  cancelSubscriptionRequestObj['cancellation_reason'] =
  'Deleted by CS Agent';
  const studentId = studentSubcriptions?.attributes?.student_id;

  useEffect(()=>{
    setSuccessMessage(null);
    setFailureMessage(null);
  }, [userData.userId]);

  useEffect(()=>{
    if (registeredCoursesLen!==0 && (coursesDeleted === registeredCoursesLen)) {
      getRegisteredCourses(userData.userId);
      setSuccessMessage('courses deleted successfully');
      setFailureMessage(null);
    }
  }, [coursesDeleted]);

  const deleteStudent = () => {
    // resetting values
    setFailedAPICount(0);
    setConfirmDelete(true);
    setCoursesNotDeleted(0);
    setCoursesDeleted(0);
    setSubscriptionAPISuccess(false);
    setStudentAPISuccess(false);
    setRegisteredCoursesLen(0);
    setSuccessMessage(null);
    setFailureMessage(null);

    patchAPICall();
    deleteCourses();
    deleteFreshSalesAPI();
    handleClose();
  };

  const openIframe = () => {
    trackAction.ctaClickAction({
      'studentId': userData?.userId,
      'agentName': cookie.load('agent_name'),
      'cta': 'Login as User',
      'action': 'LoginAsUser',
      'pageKind': 'CsLoginDashboard',
    });
    const url = `${process.env.ShawWebsiteDomain}/autosignin/`;
    const id = userData.userId;
    setIframeLink(`${url}?student_id=${id}&env=${process.env.AppicationEnv}`);
    setOpenLoginUser(true);
  };

  const closeIframe = () => {
    setIframeLink('');
    setOpenLoginUser(false);
  };

  const patchAPICall = () =>{
    const requestObject = {
      'data': {
        'type': 'students',
        'id': userData.userId,
        'attributes': {
          'email': `${userData.userId}@gmail.com`,
          'name': {
            'first_name': 'GDPR',
            'last_name': '.',
          },
          'phone': '9999999999',
        },
      },
    };
    new apiService.default.CsAgentStudentDetails(userData.userId).
        deleteStudent(requestObject)
        .then((res) => {
          trackAction.ctaClickAction({
            'studentId': userData?.userId,
            'agentName': cookie.load('agent_name'),
            'cta': 'Delete Account',
            'action': 'ConfirmDeleteAccount',
            'pageKind': 'CsLoginDashboard',
          });
          if (res.status === 200) {
            setStudentAPISuccess(true);
            setSuccessMessage('Student Details Updated');
            setFailureMessage(null);
            if (studentSubcriptions) {
              cancelSubscription();
            } else {
              setSuccessMessage('No subscriptions found');
              setSubscriptionAPISuccess(true);
            };
          }
        })
        .catch((err) => {
          setStudentAPISuccess(false);
          setFailedAPICount(failedAPICount + 1);
          setFailureMessage('Student Details updation failed');
          setSuccessMessage(null);
        });
  };

  const cancelSubscription = () =>{
    new apiService.default.CsAgentSubscriptions(userData.userId,
        studentSubcriptions && studentSubcriptions.id)
        .cancelSubscription(cancelSubscriptionRequestObj)
        .then((res) => {
          if (res.status === 200) {
            setSubscriptionAPISuccess(true);
            setSuccessMessage('Subscriptions cancelled');
            setFailureMessage(null);
            // setDeleteFreshSales(true);
            setTimeout(()=>{
              setSuccessMessage('Updating subscriptions');
            }, 1000);
            setTimeout(()=>{
              getAllSubscriptionsForStudent(studentId);
              // setDeleteFreshSales(false);
              setSuccessMessage(null);
              setFailureMessage(null);
            }, 14000);
          }
        })
        .catch((err) => {
          setFailedAPICount(failedAPICount + 1);
          setSubscriptionAPISuccess(false);
          setFailureMessage('Subscriptions cancellation failed');
          setSuccessMessage(null);
        });
  };

  const deleteCourses = () =>{
    new apiService.default.CsAgentStudentDetails(userData.userId).
        getStudentRegistrations()
        .then((res) => {
          setRegisteredCoursesLen(res.data.data.length);
          if (res.data.data.length !== 0) {
            const regCoursesLen = res.data.data.length;
            let coursesDeletedCount = 0;
            res.data.data.map((registeredCourse) =>{
              new apiService.default.CsAgentStudentDetails(registeredCourse.id).
                  deleteRegisteredCourse({
                    'data': {
                      'deferred_reason': 'GDPR',
                    }}).then((res)=>{
                    if (res.status === 204) {
                      ++coursesDeletedCount;
                      if (coursesDeletedCount === regCoursesLen) {
                        setCoursesDeleted(coursesDeletedCount);
                      }
                    }
                  })
                  .catch((err)=>{
                    setCoursesDeleted(coursesDeletedCount);
                    setCoursesNotDeleted(coursesNotDeleted + 1);
                    setFailureMessage('courses deletion failed');
                    setSuccessMessage(null);
                    getRegisteredCourses(userData.userId);
                    setFailedAPICount(failedAPICount + 1);
                  });
            });
          } else {
            setSuccessMessage('No courses found');
            setSuccessMessage(null);
            setCoursesDeleted(registeredCoursesLen);
          }
        })
        .catch((err) => {
          console.log(err);
          setFailedAPICount(failedAPICount + 1);
        });
  };
  const deleteFreshSalesAPI = () =>{
    new apiService.default.FreshSales(userData?.userInfo?.Email)
        .deleteFromFreshSales().then((res) => {
          setDeleteFreshSales(true);
          setSuccessMessage('Deleted from fresh sales');
        })
        .catch((err)=>{
          setFailedAPICount(failedAPICount + 1);
          setDeleteFreshSales(false);
          setFailureMessage('an error while deleting from fresh sales');
        });
  };

  return (
    <>
      <DisplayLayoutContainer>
        <RowFlexWrapper>
          <Title>Basic Info</Title>
          <TitleContainer>
            {
              confirmDelete?
              (studentAPISuccess && subscriptionAPISuccess &&
                deleteFreshSales &&
                (coursesNotDeleted===0)) ||
                failedAPICount>0 ?
              null:
              <div style={{margin: '0 10px 0px 0px'}}>
                <Spinner animation="border" />
              </div>:null
            }
            {/* {
               deleteFreshSales ?
              <div style={{margin: '0 10px 0px 0px'}}>
                <Spinner animation="border" />
              </div> : null
            } */}
            {
              (successMessage || failureMessage) &&
              <div style={{color: successMessage?'green':'red',
                fontSize: '12px',
                margin: '0 10px 0px 0px'}}>
                {successMessage?successMessage:failureMessage}
              </div>
            }
            {/* <Button margin0 className={Style.mr15}
              onClick={deleteFreshSalesHandler}
            >
              Delete from fresh sales
            </Button> */}
            {
              csAgent &&
                <Button
                  onClick={handleShow}
                  margin0>Delete Account
                </Button>
            }
            {
              csAgent &&
                <Button
                  onClick={openIframe}
                  style={{margin: '0 0px 0px 10px'}}>
                    Login As User
                </Button>
            }

          </TitleContainer>
        </RowFlexWrapper>
        <hr/>
        <FlexContainer>
          {
            userData?.userInfo ?
            Object.entries(userData.userInfo).map(([key, value], index) => (
              <BasicInfoWrapper key={index}>
                <KeyStyle>{key}:</KeyStyle><span>{value ? value : '-'}</span>
              </BasicInfoWrapper>
            )) : <p>No data available</p>
          }
        </FlexContainer>
        <ModalPopUp modalShow={showModal} modalTitle={'Confirm Delete'}
          modalBody={'Are you sure you want to delete'}
          closeHandler={handleClose}
          confirmHandler={deleteStudent}
        />
        <CheckoutModal
          modalShow={openLoginUser}
          url={iframeLink}
          closeHandler={()=>{
            closeIframe();
          }}
        />
      </DisplayLayoutContainer>
    </>
  );
};

BasicInfo.propTypes = {
  userData: PropTypes.object,
  studentSubcriptions: PropTypes.object,
  getAllSubscriptionsForStudent: PropTypes.func,
  getRegisteredCourses: PropTypes.func,
  csAgent: PropTypes.bool,
};

export default BasicInfo;

