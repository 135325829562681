import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Spinner} from 'react-bootstrap';
import {
  DisplayLayoutContainer,
  ChildDivDisplay,
  Title,
  Button,
  TitleContainer,
} from '../styled-components';
import * as apiService from '../../../../api';
import * as trackAction from '../../../Shared/TrackActionService';
import * as utilsService from '../../../Shared/Utils';
import cookie from 'react-cookies';
import styles from './index.module.scss';
import {CheckoutModal} from './CheckoutModal';
import {ModalPopUp} from '../ModalPopUp';
import {SUBSCRIPTION_VERSION} from '../../../../containers/Constants';

const SubscriptionActions = ({userData,
  cancelSpinner, studentSubcriptions, searchEmail, salesAgent, csAgent}) =>{
  const [showMessage, setMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [checkoutPopup, setCheckoutPopup] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [frequency, setFrequency] = useState('');
  const [priceToShow, setPriceToShow] = useState(null);
  const [paymentLink, setPaymentLink] = useState('');

  useEffect(()=>{
    if (showMessage) {
      setTimeout(()=>{
        setMessage(null);
      }, 2000);
    }
  }, [showMessage]);

  useEffect(() => {
    if (userData?.userInfo?.Email.endsWith('@mailinator.com') ||
    userData?.userInfo?.Email.endsWith('@shawacademy.com') ) {
      setShowCancelButton(true);
    }
    window.addEventListener('message', (event) => {
      if (event?.data === 'subscription_activated') {
        setCheckoutPopup(false);
      }
    });
  }, []);

  const giftMembership = (param) => {
    setInProgress(true);
    trackAction.ctaClickAction({
      'cta': `Gift ${param} Month`,
      'action': 'GiftMonth',
      'pageKind': csAgent ? 'CsAgentDashboard' : 'SalesAgentDashboard',
      'agent_email': cookie.load('agent_email'),
      'agentName': cookie.load('agent_name'),
    });
    const obj = {
      'data': {
        'type': 'subscriptions',
        'attributes': {
          'gift': {
            'current_term_start': Math.round(new Date().getTime() / 1000),
            'current_term_end': getTermEnd(param),
            'status': 'active',
            'gifter': 'CS',
          },
          'bouncer_key': 'javier',
        },
        'relationships': {
          'student': {
            'data': {
              'type': 'student',
              'id': userData?.userId,
            },
          },
        },
      },
    };
    new apiService.default.CsAgentSubscriptions()
        .giftMembership(obj)
        .then((res) => {
          setInProgress(false);
          setSuccessMessage(true);
          setTimeout(() => {
            searchEmail();
          }, 1000);
        })
        .catch((err) => {
          setInProgress(false);
          setErrorMessage(true);
        });
  };

  const getTermEnd = (param) => {
    const currentDate = new Date();
    return Math.round((new Date(currentDate.getTime() + (
      28 * param * 24 * 60 * 60 *1000)).getTime()/1000));
  };

  const cancelMembership = () => {
    let subId = studentSubcriptions?.id;
    if (studentSubcriptions?.attributes?.default) {
      subId = studentSubcriptions?.attributes?.default?.
          additional_platform_data?.id;
    }
    const studentId = userData?.userId;
    const requestObj = {};
    requestObj['lead_id'] = studentId;
    requestObj['cancellation_reason'] = 'Subscription cancelled by CS agent';

    new apiService.default.CsAgentSubscriptions(studentId, subId)
        .cancelSubscription(requestObj)
        .then((res) => {
          setInProgress(false);
          setSuccessMessage(true);
          setTimeout(() => {
            searchEmail();
          }, 1000);
        })
        .catch((err) => {
          setInProgress(false);
          setErrorMessage(true);
        });
  };

  const giftAddon = (param) => {
    setInProgress(true);
    trackAction.ctaClickAction({
      'cta': `Gift All ${param}`,
      'action': 'GiftAddons',
      'pageKind': csAgent ? 'CsAgentDashboard' : 'SalesAgentDashboard',
      'agent_email': cookie.load('agent_email'),
      'agentName': cookie.load('agent_name'),
    });
    let product = 'Cert';
    if (param === 'toolkit') {
      product = 'Toolkit';
    } else if (param === 'lifetime') {
      product = 'GiftLifetime';
    }
    const requestBody = {
      'student_id': userData?.userId,
      'line_items': [
        {
          'item_id': param,
          'item_type': 'addon',
          'allow_duplicate': true,
        },
      ],
      'gateway': {
        'name': 'gift',
      },
    };
    new apiService.default.Addons().giftAddon(requestBody)
        .then((response) => {
          window.dataLayer.push({
            'event': 'addon-purchase-action',
            'event-param': {
              'identity': userData?.userId,
              product,
              'quantity': 1,
              'pageKind': csAgent ? 'CsAgentDashboard' : 'SalesAgentDashboard',
            },
          });
          setInProgress(false);
          setSuccessMessage(true);
          setTimeout(() => {
            searchEmail();
          }, 1000);
        })
        .catch((error) => {
          setInProgress(false);
          setErrorMessage(true);
        });
  };

  const patchMembership = () => {
    setInProgress(true);
    setShowConfirmationModal(false);
    trackAction.ctaClickAction({
      'cta': `${frequency} Upgrade`,
      'action': 'UpgradeMembership',
      'pageKind': csAgent ? 'CsAgentDashboard' : 'SalesAgentDashboard',
      'agent_email': cookie.load('agent_email'),
      'agentName': cookie.load('agent_name'),
    });
    let subInfo = studentSubcriptions?.attributes?.chargebee;
    if (studentSubcriptions?.attributes?.default) {
      subInfo = studentSubcriptions?.attributes?.default
          ?.additional_platform_data;
    }
    const dataToSend = getDataToSend(subInfo, frequency);
    const domain = `${process.env.PaymentWebDomain}/payment/subscription/`;
    setPaymentLink(`${domain}${frequency}/?lead_id=${userData?.userId}`);
    new apiService.default.Addons().upgradeSubscription(dataToSend)
        .then((response) => {
          window.dataLayer.push({
            'event': 'subscription-change-action',
            'subscription-change-param': {
              'action': 'PlanChanged',
              'studentId': userData?.userId,
              'planId': dataToSend['plan_id'],
              'predicted_ltv': 0,
              'locale': 'en',
            },
          });
          setInProgress(false);
          setSuccessMessage(true);
          setTimeout(() => {
            searchEmail();
          }, 1000);
        })
        .catch((error) => {
          setInProgress(false);
          setCheckoutPopup(true);
        });
  };

  const getPrices = (frequency) => {
    new apiService.default.Addons().getUpgradePrices()
        .then((response) => {
          setFrequency(frequency);
          setPriceToShow(getCountryPrice(response, frequency));
          setShowConfirmationModal(true);
        })
        .catch((error) => {
        });
  };

  const getCountryPrice = (res, frequency) => {
    if (res?.data?.header) {
      let country = userData?.userInfo?.Country;
      if (utilsService.euCountriesList().indexOf(country) > -1) {
        country = 'eu';
      }
      let pricesToReturn=
      res?.data?.header?.pricesThreeMonth?.prices?.[country] ||
      res?.data?.header?.pricesThreeMonth?.prices?.default;
      if (frequency === 'biannual') {
        pricesToReturn=
      res?.data?.header?.pricesSixMonth?.prices?.[country] ||
      res?.data?.header?.pricesSixMonth?.prices?.default;
      } else if (frequency === 'annual') {
        pricesToReturn=
        res?.data?.header?.pricesTwelveMonth?.prices?.[country] ||
        res?.data?.header?.pricesTwelveMonth?.prices?.default;
      }
      return pricesToReturn;
    }
  };

  const getDataToSend = (subInfo, frequency) => {
    if (subInfo) {
      const countryCode = userData?.userInfo?.Country || 'us';
      const couponCodeToSend = [];
      const param = `${frequency}_0d-trial_${countryCode}`;
      const planId = `sub_${SUBSCRIPTION_VERSION}_${param}`;
      const couponId = `disc_${SUBSCRIPTION_VERSION}_${param}`;
      couponCodeToSend.push(couponId);
      return {
        student_id: userData?.userId,
        plan_id: planId,
        coupon_ids: couponCodeToSend,
        source_url: top.location.href,
      };
    }
  };

  return (
    <>
      {
        showMessage &&
        <div className={
          showMessage === 'Successful' ?
          `${styles.showAlert} ${styles.success}`:
          `${styles.showAlert} ${styles.fail}`
        }>
          {
            showMessage
          }
        </div>
      }
      <DisplayLayoutContainer>
        <TitleContainer>
          <Title>
            Actions
          </Title>
          <div style={{
            'display': 'flex',
            'justifyContent': 'flex-end',
            'alignItems': 'center',
          }}>
            { inProgress && <ChildDivDisplay
              style={{'backgroundColor': '#ffffff', 'marginRight': '10px'}}
            >
              { <div style={{
                color: 'green',
                fontSize: '12px',
                margin: '10px 10px 0px 20px'}}
              >
                    In Progress
              </div>}
            </ChildDivDisplay >}
            { successMessage && <ChildDivDisplay
              style={{'backgroundColor': '#ffffff', 'marginRight': '10px'}}
            >
              { <div style={{
                color: 'green',
                fontSize: '12px',
                margin: '10px 10px 0px 20px'}}
              >
                    Action Successful
              </div>}
            </ChildDivDisplay >}
            { errorMessage && <ChildDivDisplay
              style={{'backgroundColor': '#ffffff', 'marginRight': '10px'}}
            >
              { <div style={{
                color: 'green',
                fontSize: '12px',
                margin: '10px 10px 0px 20px'}}
              >
                    Action Failed, please try again!
              </div>}
            </ChildDivDisplay >}
            <div
              style={{margin: '10px 10px 0px 0px', display: 'none'}}
              ref={cancelSpinner}
            >
              <Spinner animation="border" style={{marginRight: '10px'}}/>
            </div>
          </div>
        </TitleContainer>
        <hr/>

        {(!salesAgent && !studentSubcriptions?.attributes?.itunes) &&
         <div className={styles.ctaWrapper}
           style={{margin: '20px 0'}}>
           <Button
             disabled={studentSubcriptions?.attributes}
             onClick={() => giftMembership(3)}
           >
            Gift Three Month
           </Button>
           <Button
             disabled={studentSubcriptions?.attributes}
             onClick={() => giftMembership(6)}
           >
            Gift Six Month
           </Button>
           <Button
             disabled={studentSubcriptions?.attributes}
             onClick={() => giftMembership(12)}
           >
            Gift Tweleve Month
           </Button>
           <Button
             disabled={false}
             onClick={() => giftAddon('toolkit')}
           >
            Get All Tookits
           </Button>
           <Button
             disabled={false}
             onClick={() => giftAddon('certificate')}
           >
            Get All Certificates
           </Button>
           <Button
             disabled={false}
             onClick={() => giftAddon('lifetime')}
           >
            Gift Lifetime
           </Button>
           {showCancelButton &&
          <Button
            disabled={false}
            onClick={() => cancelMembership()}
          >
            Cancel Membership
          </Button>}
         </div>}
        {
          (salesAgent && !studentSubcriptions?.attributes?.itunes) &&
          <div className={styles.ctaWrapper} style={{margin: '20px 0'}}>
            <Button
              disabled={false}
              onClick={() => getPrices('quarterly')}
            >
            3M Upgrade(oneclick)
            </Button>
            <Button
              disabled={false}
              onClick={() => getPrices('biannual')}
            >
            6M Upgrade(oneclick)
            </Button>
            <Button
              disabled={false}
              onClick={() => getPrices('annual')}
            >
            12M Upgrade(oneclick)
            </Button>
          </div>
        }
        <CheckoutModal
          modalShow={checkoutPopup}
          url={paymentLink}
          closeHandler={()=>{
            setCheckoutPopup(false);
          }}
        />
        <ModalPopUp
          modalShow={showConfirmationModal}
          modalTitle={'Current Prices'}
          modalBody={
            `Total Price - ${priceToShow?.total_price} 
            ${priceToShow?.currency_code}`
          }
          closeHandler={()=>setShowConfirmationModal(false)}
          confirmHandler={patchMembership}
        />
      </DisplayLayoutContainer>
    </>
  );
};

SubscriptionActions.propTypes = {
  studentSubcriptions: PropTypes.object,
  searchEmail: PropTypes.func,
  cancelSpinner: PropTypes.any,
  userData: PropTypes.object,
  salesAgent: PropTypes.bool,
  csAgent: PropTypes.bool,
};

export default SubscriptionActions;
