import React from 'react';
import {Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

export const CheckoutModal = ({modalShow, url, closeHandler}) =>{
  return (
    <Modal
      show={modalShow}
      onHide={closeHandler}
      className={styles.modal}
    >
      <Modal.Header closeButton/>
      <Modal.Body>
        <iframe src={url} width="100%" height="100%" />
      </Modal.Body>
    </Modal>
  );
};

CheckoutModal.propTypes = {
  modalShow: PropTypes.bool,
  url: PropTypes.string,
  closeHandler: PropTypes.func,
};
