import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  DisplayLayoutContainer,
  Title,
  TitleContainer,
} from '../styled-components';
import * as apiService from '../../../../api';
import * as commonService from '../CommonService';

const AddonInfo = ({userData}) =>{
  const [addonInfo, setAddonInfo] = useState([]);
  const [toolkitAddonArray, setToolkitAddonArray] = useState([]);
  const [certAddonArray, setCertAddonArray] = useState([]);
  const [hcdAddonArray, setHcdAddonArray] = useState([]);
  useEffect(() => {
    if (userData?.userId) {
      new apiService.default.Addons(userData?.userId).list()
          .then((res) => (res.data)).then((res) => {
            setAddonInfo(commonService.getMergedResponse(res.data));
          }).catch((err) => {
            setAddonInfo([]);
          });
    }
  }, [userData]);

  useEffect(() => {
    if (addonInfo?.length) {
      segregateAddons(addonInfo);
    }
  }, [addonInfo]);

  const segregateAddons = (addons) => {
    const toolkitAddons = [];
    const certAddons = [];
    const hcdAddons = [];
    for (const addon of addons) {
      if (addon.indexOf('module-toolkit')>-1) {
        const replacedString = addon.replace('module-toolkit-', '');
        toolkitAddons.push(replacedString);
        setToolkitAddonArray(toolkitAddons);
      } else if (addon.indexOf('module-cert')>-1) {
        const replacedString = addon.replace('module-cert-', '');
        certAddons.push(replacedString);
        setCertAddonArray(certAddons);
      } else if (addon.indexOf('course-hard-copy-diploma')>-1) {
        const replacedString = addon.replace('course-hard-copy-diploma-', '');
        hcdAddons.push(replacedString);
        setHcdAddonArray(hcdAddons);
      }
    }
  };

  return (
    <>
      <DisplayLayoutContainer>
        <TitleContainer>
          <Title>
            Addons Purchased
          </Title>
        </TitleContainer>
        <hr/>
        <p style={{textAlign: 'left',
          margin: '20px 0', fontWeight: 'bold'}}>Module Toolkits :-</p>
        <div style={{textAlign: 'left',
          margin: '20px 0'}}>
          {toolkitAddonArray.length && toolkitAddonArray.map((item, index)=>(
            <p key={index}>
              {item}<br />
            </p>
          ))}
        </div>
        <hr/>
        <p style={{textAlign: 'left',
          margin: '20px 0', fontWeight: 'bold'}}>Module Cert :-</p>
        <div style={{textAlign: 'left',
          margin: '20px 0'}}>
          {certAddonArray.length && certAddonArray.map((item, index)=>(
            <p key={index}>
              {item}<br />
            </p>
          ))}
        </div>
        <hr/>
        <p style={{textAlign: 'left',
          margin: '20px 0', fontWeight: 'bold'}}>Hard Copy Diploma :-</p>
        <div style={{textAlign: 'left',
          margin: '20px 0'}}>
          {hcdAddonArray.length && hcdAddonArray.map((item, index)=>(
            <p key={index}>
              {item}<br />
            </p>
          ))}
        </div>
      </DisplayLayoutContainer>
    </>
  );
};

AddonInfo.propTypes = {
  userData: PropTypes.object,
};

export default AddonInfo;
