import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  DisplayLayoutContainer,
  RowFlexWrapper,
  Title,
  FlexContainer,
  TitleContainer,
} from '../styled-components';
import cookie from 'react-cookies';
import '../index.module.scss';
import {ModalPopUp} from '../ModalPopUp';
import {RescheduleModal} from './RescheduleModal';
import * as apiService from '../../../../api';
import * as CommonService from '../CommonService';
import {Accordion, Card, Spinner, Button} from 'react-bootstrap';
import * as trackAction from '../../../Shared/TrackActionService';
import Styles from './index.module.scss';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as registeredCourseService from './RegisteredCoursesDetailsService';
// import {getPrices} from '../../../../api/AddonPrices';

dayjs.extend(utc);

export const RegisteredCoursesDetails = ({studentRegistrations,
  isLoading, getRegisteredCourses, csAgent, userData, salesAgent,
  reactivationAgent, susAgent}) => {
  const [registeredCourses, setRegisteredCourses] = useState(null);
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  // confirm toolkit popup
  // const [confirmToolkitPopup, setConfirmToolkitPopup] = useState(false);
  // const [toolkitObject, setToolkitObject] = useState(null);

  const [inProgressLoader, setInProgressLoader] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);
  const [failureLoader, setFailureLoader] = useState(false);

  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [assignmentInfo, setAssignmentInfo] = useState({});
  const [showAssessmentsModal, setAssessmentsModal] = useState(false);
  const [registeredId, setRegisteredId] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [failureMessage, setFailureMessage] = useState(null);
  const [firstSchedule, setFirstSchedule] = useState(null);
  const [secondSchedule, setSecondSchedule] = useState(null);
  const [lessonsArray, setLessonsArray] = useState(null);
  const [courseSlug, setCourseSlug]= useState(null);
  const [moduleNumberToApi, setModuleNumber] = useState(1);
  const agentName = cookie.load('agent_name');
  let agentType = 'csagent';
  if (salesAgent) {
    agentType = 'salesagent';
  } else if (reactivationAgent) {
    agentType = 'reactivationagent';
  }
  const leadId = userData.userId;


  // show schedule
  const [showSchedulePopup, setShowSchedulePopup] = useState(false);
  const [schedule, setSchedule]= useState(null);
  const [ModuleRegId, setModuleRegId] = useState(null);

  // restart module popup
  const [restartModuleObject, setRestartModuleObject] = useState(null);

  useEffect(()=>{
    studentRegistrations?.length > 5 ?
    setRegisteredCourses(studentRegistrations.slice(0, 5)):
    setRegisteredCourses(studentRegistrations);
    if (studentRegistrations) {
      setActiveModules(studentRegistrations);
    }
  }, [studentRegistrations]);

  useEffect(()=>{
    if (successMessage || failureMessage) {
      setTimeout(()=>{
        setSuccessMessage(null);
        setFailureMessage(null);
      }, 3000);
    }
  }, [successMessage, failureMessage]);

  /**
   * invoke registerCourse when all parameters are present
   */
  useEffect(()=>{
    if (firstSchedule && secondSchedule && lessonsArray && courseSlug) {
      registerCourse();
    }
  }, [secondSchedule, firstSchedule, lessonsArray, courseSlug]);


  // useEffect(()=>{
  //   if (toolkitObject) {
  //     setConfirmToolkitPopup(true);
  //     setShowSpinner(false);
  //   }
  // }, [toolkitObject]);

  /**
   * get the current active module
   * @param {*} studentRegistrations
   */
  const setActiveModules = (studentRegistrations) =>{
    setShowSpinner(true);
    studentRegistrations.map((registration) => {
      const data=registration?.relationships?.modules?.data;
      let moduleCompleted=null;
      for (let i=0; i<data.length; i++) {
        if (!registration?.attributes?.activeModuleNumber) {
          if (data[i]?.includedData?.attributes?.registration_id) {
            moduleCompleted=i+1;
            const lessons=data[i]?.includedData?.attributes?.lessons;
            for (let j=0; j<lessons.length; j++) {
              if (lessons[j].start_time >= dayjs().utc().format()) {
                registration.attributes['activeModuleNumber']=i+1;
                moduleCompleted=null;
                break;
              }
            }
          }
        }
      }
      if (moduleCompleted) {
        registration.attributes['moduleCompleted']=moduleCompleted;
      }
    });
    setRegisteredCourses(studentRegistrations);
    setShowSpinner(false);
  };

  /**
   * schedule(register) the course
   * @param {*} registrationObj
   */
  const registerCourse = (registrationObj) =>{
    registrationObj ? setShowSpinner(true) : null;
    // let firstDayOfWeek;
    // let secondDayOfWeek;
    const lessons= registrationObj?.lessonsArray ?
    registrationObj?.lessonsArray : lessonsArray;

    const slug= registrationObj?.courseSlug ?
    registrationObj?.courseSlug : courseSlug;

    const schedule1 = registrationObj?.schedule1?
    registrationObj?.schedule1 : firstSchedule;

    const schedule2 = registrationObj?.schedule2?
    registrationObj?.schedule2 : secondSchedule;
    const {firstDayOfWeek, secondDayOfWeek} =
      CommonService.getDaysOfWeek(schedule1, schedule2);

    // get requestObject
    const createScheduleObject = {
      courseStartDate: schedule1.toISOString(),
      courseSlug: slug,
      firstDayOfWeek,
      secondDayOfWeek,
      firstDayHour: parseInt(schedule1.format('H')),
      secondDayHour: parseInt(schedule2.format('H')),
      lessons,
      leadId: userData.userId,
    };
    const requestObj = CommonService.
        createScheduleReqObject(createScheduleObject);

    new apiService.default.Schedule().create(requestObj)
        .then((response) => {
          const result = response.data.data;
          if (result) {
            trackAction.registrationAction({
              'moduleNumber': moduleNumberToApi,
              'courseSlug': result.attributes.course_slug,
              'facultySlug': result.attributes.course.faculty,
              'locale': 'en',
              'Goal': 2,
              'rescheduleType': 'changeSchedule',
            });
            getRegisteredCourses(userData.userId);
          } else if (response.data.errors[0].code == '409') {
            setTimeout(()=>{
              setFailureMessage('Conflict');
            }, 2000);
          }
          setShowSpinner(false);
        })
        .catch((error) => {
          setFailureMessage('Error in scheduling');
          setShowSpinner(false);
        });
  };


  /**
  *
  *
  * @param {*} registrationObj
  * @return {*}
  */
  const rescheduleCourse = (registrationObj) => {
    return new apiService.default.Schedule(registrationObj.data.id).
        update(registrationObj)
        .then((response) => {
          const result = response.data.data;
          if (result) {
            trackAction.registrationAction({
              'moduleNumber': moduleNumberToApi,
              'courseSlug': result.attributes.course_slug,
              'facultySlug': result.attributes.course.faculty,
              'locale': 'en',
              'Goal': 2,
              'rescheduleType': 'restartModule',
            });
            // getRegisteredCourses(userData.userId);
          } else if (response.data.errors[0].code == '409') {
            setFailureMessage('conflict');
          }
          setShowSpinner(false);
          return result;
        })
        .catch((error) => {
          // alert(error);
          console.log(error);
          setFailureMessage('Error in scheduling');
          setShowSpinner(false);
          return error;
        });
  };

  /**
   *
   * add module functionality
   * @param {*} registration
   */
  function addModule(registration) {
    setShowSpinner(true);
    const moduleNo = registration?.attributes?.moduleCompleted;
    let pageKind = 'CsAgentDashboard';
    if (salesAgent) {
      pageKind = 'SalesAgentDashboard';
    } else if (reactivationAgent) {
      pageKind = 'ReactivationAgentDashboard';
    }
    trackAction.ctaClickAction({
      'studentId': userData.userId,
      'cta': 'Add Next Module',
      'action': 'AddNextModule',
      'courseSlug': registration?.relationships?.
          modules?.data[moduleNo]?.id,
      'moduleNumber': moduleNo+1,
      pageKind,
    });
    if (registration) {
      const moduleCompleted=registration?.attributes?.moduleCompleted;
      setModuleNumber(registration?.attributes?.moduleCompleted);

      const currentSchedule1=dayjs().add(1, 'day');
      const currentSchedule2=dayjs().add(7, 'day');

      setFirstSchedule(currentSchedule1);
      setSecondSchedule(currentSchedule2);
      setCourseSlug(registration?.relationships?.
          modules?.data[moduleCompleted]?.id);

      // build lesson array
      const lessonLength =registration?.relationships?.
          modules?.data[moduleCompleted]?.includedData?.
          attributes?.lessons?.length;
      const lessons = CommonService.
          getLessonArray(currentSchedule1, currentSchedule1, lessonLength);
      setLessonsArray(lessons);
    }
  };

  /**
   *
   * @param {*} registration
   * delete the registered courses and reschedule the course
   */
  const restartCourse =(registration) =>{
    let pageKind = 'CsAgentDashboard';
    if (salesAgent) {
      pageKind = 'SalesAgentDashboard';
    } else if (reactivationAgent) {
      pageKind = 'ReactivationAgentDashboard';
    }
    setShowSpinner(true);
    trackAction.ctaClickAction({
      'studentId': userData.userId,
      'cta': 'Restart Course',
      'action': 'RestartCourse',
      pageKind,
    });
    if (registration) {
      const lessons = registration?.relationships?.
          modules?.data?.[0]?.includedData?.attributes?.lessons;

      const promises = registration?.relationships?.
          modules?.data.map((data, i)=>{
            const regId = data?.includedData?.attributes?.registration_id;
            const schedule1 = registeredCourseService.
                getSchedule(dayjs(lessons?.[0]?.start_time))
                .add(i===0 ? 7 : 7*(i*Math.ceil(lessons.length/2))+7, 'day');

            const schedule2 = registeredCourseService.
                getSchedule(dayjs(lessons?.[1]?.start_time))
                .add(i===0 ? 7 : 7*(i*Math.ceil(lessons.length/2))+7, 'day');
            if (regId) {
              const registeredModuleObject={
                schedule1,
                schedule2,
                courseSlug: registration?.attributes?.courseslug,
                regId,
                lessons: lessons,
                registrationData: registration?.relationships?.modules?.data,
              };
              // const lessonsArray = registeredCourseService.
              //     buildLessonArray(registeredModuleObject);

              const lessonsArray = CommonService.
                  getLessonArray(schedule1, schedule2, lessons.length);

              const rescheduleObject = {...registeredModuleObject};
              rescheduleObject['lessonsArray']=lessonsArray;
              const requestObject = registeredCourseService.
                  getRescheduleCourseObject(rescheduleObject, userData.userId);
              return rescheduleCourse(requestObject);
            }
          });
      Promise.all(promises).then((response)=>{
        setSuccessMessage('Successfully restarted the course');
        getRegisteredCourses(userData.userId);
      })
          .catch((error)=>{
            console.log(error);
            setFailureMessage('Failed to restart course');
          });
    }
  };

  /**
   *
   * @param {*} registration
   * @param {*} moduleNo
   * delete the registered courses and reschedule the course
   */
  const restartModule =({registration, moduleNo}) =>{
    let pageKind = 'CsAgentDashboard';
    if (salesAgent) {
      pageKind = 'SalesAgentDashboard';
    } else if (reactivationAgent) {
      pageKind = 'ReactivationAgentDashboard';
    }
    setRestartModuleObject(null);
    setShowSpinner(true);
    trackAction.ctaClickAction({
      'studentId': userData.userId,
      'cta': 'Restart Module',
      'action': 'RestartModule',
      pageKind,
    });
    const activeModuleNo = registration?. attributes?.activeModuleNumber;
    const moduleCompleted = registration?. attributes?.moduleCompleted;

    // if activeModule is not present then consider moduleCompleted
    const lessonReferenceModuleNo = activeModuleNo || moduleCompleted;
    if (registration) {
      const module= registration?.relationships?.
          modules?.data?.[moduleNo];
      const lessons = registration?.relationships?.
          modules?.data?.[lessonReferenceModuleNo-1]?.
          includedData?.attributes?.lessons;

      const regId = module?.includedData?.attributes?.registration_id;
      const schedule1 = registeredCourseService.
          getSchedule(dayjs(lessons?.[0]?.start_time));

      let schedule2 = registeredCourseService.
          getSchedule(dayjs(lessons?.[1]?.start_time));

      if (schedule1.day()>schedule2.day()) {
        schedule2 = dayjs(schedule2).add(7, 'day');
      }
      if (regId) {
        const registeredModuleObject={
          schedule1,
          schedule2,
          courseSlug: module?.includedData?.attributes?.moduleslug,
          regId,
          lessons: lessons,
          registrationData: registration?.relationships?.modules?.data,
        };
        const lessonsArray = CommonService.
            getLessonArray(schedule1, schedule2, lessons.length);

        const rescheduleObject = {...registeredModuleObject};
        rescheduleObject['lessonsArray']=lessonsArray;
        const requestObject = registeredCourseService.
            getRescheduleCourseObject(rescheduleObject, userData.userId);

        // Reschedule module
        rescheduleCourse(requestObject).then((response)=>{
          setSuccessMessage('Successfully restarted the module');
          getRegisteredCourses(userData.userId);
          setShowSpinner(false);
        })
            .catch((error)=>{
              setShowSpinner(false);
              console.log(error);
              setFailureMessage('Failed to restart module');
            });
      } else {
        setFailureMessage('Module is not registered');
        setShowSpinner(false);
      }
    }
  };

  /**
   *
   *
   * @param {*} module
   * @param {*} index
   * @param {*} cta
   * @param {*} action
   * @param {*} addOnName
   */
  // const addOn =(module, index, cta, action, addOnName) => {
  //   trackAction.ctaClickAction({
  //     'studentId': userData.userId,
  //     'cta': cta,
  //     'action': action,
  //     'pageKind': 'ReactivationAgentDashboard',
  //     'agentName': agentName,
  //   });
  //   setShowSpinner(true);
  //   const addons = {
  //     product: 'module-toolkit',
  //     type: 'toolkit',
  //   };
  //   getPrices(addons, userData.userInfo.Country)
  //       .then((response)=>{
  //         const getToolkitObj = {
  //           currencyCode: response.currency_code,
  //           activeTierCode: response.default_tier_code,
  //           addon: addOnName,
  //           country: userData.userInfo.Country,
  //           slug: module?.includedData?.attributes?.moduleslug,
  //           moduleSlug: module?.includedData?.attributes?.moduleslug,
  //           moduleNumber: index+1,
  //           studentId: userData.userId,
  //           regId: module?.includedData?.attributes?.registration_id,
  //           price: response?.default_tier_price,
  //         };
  //         setToolkitObject(getToolkitObj);
  //       })
  //       .catch((error)=>{
  //         console.log(error);
  //         setShowSpinner(false);
  //         setFailureMessage('Get prices api failed');
  //       });
  // };

  /**
   *invoke after confirming toolkit popup
   *
   */
  // const buyToolkit = () =>{
  //   setConfirmToolkitPopup(false);
  //   setShowSpinner(true);
  //   const clonedToolkit = {...toolkitObject};
  //   delete clonedToolkit.price;
  //   registeredCourseService.addonPurchaseAction(toolkitObject,
  //       setShowSpinner, setSuccessMessage, setFailureMessage);
  // };

  /**
   * reschedule cta handler
   * @param {*} lessons
   * @param {*} regId
   */
  const handleRescheduleModal = (lessons, regId) =>{
    setLessons(lessons);
    setRegisteredId(regId);
    setShowRescheduleModal(true);
  };

  /**
   * get assignment info
   * @param {*} moduleInfo
   */
  const getAssignmentInfo = (moduleInfo) => {
    let pageKind = 'CsAgentDashboard';
    if (salesAgent) {
      pageKind = 'SalesAgentDashboard';
    } else if (reactivationAgent) {
      pageKind = 'ReactivationAgentDashboard';
    } else if (susAgent) {
      pageKind = 'SusAgentDashboard';
    }
    trackAction.ctaClickAction({
      'studentId': userData.userId,
      'cta': 'Get Assignment Info',
      'action': 'GetAssignmentInfo',
      pageKind,
      'agentName': agentName,
    });
    setAssessmentsModal(true);
    const id = moduleInfo?.includedData?.attributes?.registration_id;
    new apiService.default.CsAgentStudentDetails().
        getAssignmentInfo(id).then((res) => {
          setAssignmentInfo({});
          if (res.data.data.length) {
            setAssignmentInfo(res.data.data[0]);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
  };

  /**
   * confirmation to delete course
   * @param {*} courseId
   */
  const confirmDeleteCourse = (courseId) => {
    setShowDeletePopUp(true);
    setRegisteredId(courseId);
  };

  /**
   * Close assessment modal handlerx
   */
  const closeAssessmentModal = () => {
    setAssessmentsModal(false);
    setAssignmentInfo({});
  };

  /**
   * Delete the registered course
   */
  const deleteRegisteredCourse = () => {
    let pageKind = 'CsAgentDashboard';
    if (salesAgent) {
      pageKind = 'SalesAgentDashboard';
    } else if (reactivationAgent) {
      pageKind = 'ReactivationAgentDashboard';
    }
    trackAction.ctaClickAction({
      'studentId': userData.userId,
      'cta': 'Delete Registration',
      'action': 'DeleteRegistration',
      pageKind,
      'agentName': agentName,
    });
    setShowSpinner(true);
    setShowDeletePopUp(false);
    new apiService.default.CsAgentStudentDetails(registeredId).
        deleteRegisteredCourse({
          'data': {
            'deferred_reason': 'Deleted by Agent',
          }}).then((res) => {
          if (res.status === 204) {
            setShowSpinner(false);
            getRegisteredCourses(userData.userId);
          }
        })
        .catch((err) => {
          setShowSpinner(false);
        });
  };

  const giftAddons = (param, moduleInfo, allModules = '') => {
    setInProgressLoader(true);
    let pageKind = 'CsAgentDashboard';
    if (salesAgent) {
      pageKind = 'SalesAgentDashboard';
    } else if (reactivationAgent) {
      pageKind = 'ReactivationAgentDashboard';
    } else if (susAgent) {
      pageKind = 'SusAgentDashboard';
    }
    setShowSpinner(true);
    trackAction.ctaClickAction({
      'cta': `Gift ${param}`,
      'action': 'GiftAddons',
      pageKind,
      'agent_email': cookie.load('agent_email'),
      'agentName': cookie.load('agent_name'),
    });
    let product = 'Cert';
    let itemId = `module-cert`;
    if (param === 'toolkit') {
      product = 'Toolkit';
      itemId = `module-toolkit`;
    } else if (param === 'hcd') {
      product = 'HardCopyDiploma';
      itemId = `course-hard-copy-diploma`;
    }
    const obj = {
      'student_id': userData?.userId,
      'line_items': getLineItems(itemId, param, allModules, moduleInfo),
      'gateway': {
        'name': 'gift',
      },
    };
    new apiService.default.Addons().giftAddon(obj)
        .then((response) => {
          let pageKind = 'CsAgentDashboard';
          if (salesAgent) {
            pageKind = 'SalesAgentDashboard';
          } else if (reactivationAgent) {
            pageKind = 'ReactivationAgentDashboard';
          }
          window.dataLayer.push({
            'event': 'addon-purchase-action',
            'event-param': {
              'identity': userData?.userId,
              product,
              'quantity': 1,
              pageKind,
            },
          });
          setTimeout(() => {
            setShowSpinner(false);
            setInProgressLoader(false);
            setSuccessLoader(true);
            searchEmail();
          }, 1000);
        })
        .catch((error) => {
          setInProgressLoader(false);
          setFailureLoader(true);
        });
  };

  const getLineItems = (itemId, param, allModules, moduleInfo) => {
    const lineItems = [];
    if (!allModules) {
      return [
        {
          'item_id': itemId,
          'item_type': 'addon',
          'eqf_slug': (param === 'hcd' || allModules) ? moduleInfo?.id :
          moduleInfo?.moduleslug,
          'module_number': getModuleNumberToPass(param, allModules, moduleInfo),
          'allow_duplicate': true,
        },
      ];
    }
    for (let i = 0; i < 4; i++) {
      let obj = {};
      obj = {
        item_id: itemId,
        item_type: 'addon',
        eqf_slug: moduleInfo?.id,
        module_number: i + 1,
        allow_duplicate: true,
      };
      lineItems.push(obj);
    }
    return lineItems;
  };

  const getModuleNumberToPass = (param, allModules, moduleInfo) => {
    if (allModules) {
      return [1, 2, 3, 4];
    }
    if (param === 'hcd') {
      return null;
    }
    getModuleNumber(moduleInfo?.moduleslug);
  };

  const getModuleNumber = (slug) => {
    if (slug?.indexOf('intermediate') > -1) {
      return 2;
    }
    if (slug?.indexOf('advanced') > -1) {
      return 3;
    }
    if (slug?.indexOf('proficient') > -1) {
      return 4;
    }
    return 1;
  };

  /**
   *
   * show the schedule for all the lessons in module
   * @param {*} module
   */
  const showSchedule = (module) => {
    let pageKind = 'CsAgentDashboard';
    if (salesAgent) {
      pageKind = 'SalesAgentDashboard';
    } else if (reactivationAgent) {
      pageKind = 'ReactivationAgentDashboard';
    } else if (susAgent) {
      pageKind = 'SusAgentDashboard';
    }
    trackAction.ctaClickAction({
      'studentId': userData.userId,
      'cta': 'Show Schedule',
      'action': 'ShowSchedule',
      pageKind,
      'agentName': agentName,
    });
    const lessons = module?.includedData?.attributes?.lessons;
    const regIdCurrentModule = module?.includedData.attributes.registration_id;
    setModuleRegId(regIdCurrentModule);
    setSchedule(lessons);
    setShowSchedulePopup(true);
  };

  return (
    <>
      <DisplayLayoutContainer>
        <RowFlexWrapper>
          <Title>Registered Courses</Title>
          <TitleContainer>
            {
                showSpinner || isLoading ?
                <div style={{margin: '0 10px 0px 0px'}}>
                  <Spinner animation="border" />
                </div> : null
            }
            {
              (successMessage || failureMessage) &&
                <div style={{color: successMessage?'green':'red',
                  fontSize: '12px',
                  margin: '0 10px 0px 0px'}}>
                  {successMessage?successMessage:failureMessage}
                </div>
            }
            { inProgressLoader &&
              <div style={{color: 'green',
                fontSize: '12px',
                margin: '0 10px 0px 0px'}}>
                In Progress
              </div>
            }
            { successLoader &&
              <div style={{color: 'green',
                fontSize: '12px',
                margin: '0 10px 0px 0px'}}>
                Action Success
              </div>
            }
            { failureLoader &&
              <div style={{color: 'red',
                fontSize: '12px',
                margin: '0 10px 0px 0px'}}>
                Action Failed
              </div>
            }
          </TitleContainer>
        </RowFlexWrapper>
        <hr/>
        <FlexContainer>
          <Accordion style={{'width': '100%'}} defaultActiveKey='0'>
            {
              registeredCourses && registeredCourses.length!==0 ?
                registeredCourses.map((registration, index)=>(
                  <Card key={index}>
                    <Card.Header
                      style=
                        {{
                          'display': 'flex',
                          'justifyContent': 'space-between',
                          'alignItems': 'center',
                        }}
                    >
                      <Accordion.Toggle variant="link" eventKey={`'${index}'`}
                        className={Styles.accordionLink}>
                        <span>
                          {registration.attributes.coursename}
                        </span>
                        {
                          registration?.attributes?.activeModuleNumber &&
                            <div className={Styles.activeModuleWrapper}>
                              {`(Active module : 
                                ${registration.attributes.activeModuleNumber})`}
                            </div>
                        }
                        {
                          registration?.attributes?.moduleCompleted &&
                            <div className={Styles.completedModuleWrapper}>
                              {`(Module ${registration.attributes.
                                  moduleCompleted}
                                completed)`}
                            </div>
                        }
                      </Accordion.Toggle>
                      {
                      userData?.userInfo?.Slug !== 'gift-premium' &&
                        registration?.attributes?.moduleCompleted &&
                          <Button
                            className={Styles.addCourse}
                            onClick={()=>addModule(registration)}
                          >
                          Add next module
                          </Button>
                      }
                      {
                        !csAgent && !salesAgent && !susAgent &&
                      <>
                        {/* <Button
                          className={Styles.ecert}
                          onClick={()=>addOn(
                            registration?.relationships?.
                                modules?.data[0],
                            index, 'Ecert', 'Ecert', 'cert',
                          )}
                        >
                          Ecert
                        </Button> */}
                        {/* <Button
                          className={Styles.hcd}
                          onClick={()=>addOn(
                            registration?.relationships?.
                                modules?.data[0],
                            index, 'HCD', 'HCD', 'hard-copy-diploma',
                          )}
                        >
                         HCD
                        </Button> */}
                        {/* <Button
                          onClick={()=>addOn(
                            registration?.relationships?.
                                modules?.data[0],
                            index, 'Toolkit', 'Toolkit', 'toolkit',
                          )}
                          className={Styles.toolkit}
                        >
                          Toolkit
                        </Button> */}
                        <Button
                          className={Styles.restartCourse}
                          onClick={()=>restartCourse(registration)}
                        >
                          Restart Course
                        </Button>
                      </>
                      }
                      { (susAgent || reactivationAgent || salesAgent) &&
                        <Button
                          className={Styles.ecert}
                          onClick={()=>
                            giftAddons('hcd', registration?.relationships?.
                                modules?.data[0])}
                        >
                        Gift HCD
                        </Button>
                      }
                      { (susAgent || reactivationAgent || salesAgent) &&
                        <Button
                          className={Styles.ecert}
                          onClick={()=>
                            giftAddons('toolkit', registration?.relationships?.
                                modules?.data[0], true)}
                        >
                        Gift Toolkit
                        </Button>
                      }
                      { (susAgent || reactivationAgent || salesAgent) &&
                        <Button
                          className={Styles.ecert}
                          onClick={()=>
                            giftAddons('cert', registration?.relationships?.
                                modules?.data[0], true)}
                        >
                        Gift Cert
                        </Button>
                      }
                      <div style={{cursor: 'pointer'}}
                        onClick={()=>
                          confirmDeleteCourse(registration.id)}>
                        <img src='/delete-icon.png' alt='delete'/>
                      </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey={`'${index}'`}>
                      <Card.Body>
                        {
                          registration?.relationships?.modules?.
                              data.map((module, index)=>(
                                module?.includedData &&
                                <RowFlexWrapper key={index}
                                  style={{marginRight: '20px'}}>
                                  <span>{module?.includedData?.
                                      attributes?.modulename}</span>
                                  {
                                    module?.includedData?.
                                        attributes?.registration_id &&
                                        (csAgent ||
                                          susAgent ||
                                          reactivationAgent) &&
                                        <RowFlexWrapper
                                          style={{'width': 'unset'}}
                                        >
                                          <Button
                                            className={Styles.rescheduleCourse}
                                            onClick={()=>
                                              handleRescheduleModal(module?.
                                                  includedData?.
                                                  attributes?.
                                                  lessons, module?.
                                                      includedData?.
                                                      attributes?.
                                                      registration_id)
                                            }
                                          >
                                            Reschedule
                                          </Button>
                                          {(csAgent || susAgent) &&
                                            <Button
                                              className=
                                                {Styles.getAssignmentInfo}
                                              onClick={()=>
                                                getAssignmentInfo(module)
                                              }
                                            >
                                              Get Assignment Info
                                            </Button>
                                          }
                                          <Button
                                            className={module?.
                                                includedData?.
                                                attributes?.
                                                registration_id ?
                                              Styles.showSchedule :
                                              Styles.disabled}
                                            onClick={()=>showSchedule(module)}
                                          >
                                          Show Schedule
                                          </Button>
                                        </RowFlexWrapper>
                                  }
                                  {/* {
                                    salesAgent &&
                                    <div className={Styles.buttonWrapper}>
                                      <Button
                                        className={Styles.ecert}
                                        onClick={()=>
                                          giftAddons('cert', module?.
                                              includedData?.attributes)}
                                      >
                                        Gift Ecert
                                      </Button>
                                      <Button
                                        className={Styles.ecert}
                                        onClick={()=>
                                          giftAddons('toolkit', module?.
                                              includedData?.attributes)}
                                      >
                                        Gift Toolkit
                                      </Button>
                                    </div>
                                  } */}
                                  {/* {
                                    !csAgent && !salesAgent &&
                                    <div className={Styles.buttonWrapper}>
                                      <Button
                                        className = {module?.includedData?.
                                            attributes?.registration_id ?
                                            Styles.restartModule :
                                            Styles.disabled}
                                        // onClick={()=>
                                        //   setRestartModuleObject({
                                        //     'registration': registration,
                                        //     'moduleNo': index,
                                        //   })
                                        // }
                                        onClick={() =>
                                          handleRescheduleModal(module?.
                                              includedData?.
                                              attributes?.
                                              lessons, module?.
                                                  includedData?.
                                                  attributes?.
                                                  registration_id)}

                                      >
                                      Restart Module
                                      </Button>
                                      <Button
                                        className={module?.
                                            includedData?.
                                            attributes?.
                                            registration_id ?
                                            Styles.showSchedule :
                                            Styles.disabled}
                                        onClick={()=>showSchedule(module)}
                                      >
                                        Show Schedule
                                      </Button>
                                    </div>
                                  } */}
                                </RowFlexWrapper>
                              ))
                        }
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                )):<p style={{'textAlign': 'left'}}>No courses available</p>
            }
            {
              isLoading &&
              <Spinner animation="grow" role="status"
                style={{'margin': '16px auto'}}>
                <span className="sr-only">Loading...</span>
              </Spinner>
            }
          </Accordion>
          {
              studentRegistrations?.length > 5?
                <RowFlexWrapper style={{justifyContent: 'flex-end',
                  marginRight: '20px', cursor: 'pointer', color: '#21243d'}}>
                  {
                      studentRegistrations?.length ===
                      registeredCourses?.length ?
                      <a onClick={()=>setRegisteredCourses(studentRegistrations.
                          slice(0, 5))}>show less</a>:
                      <a
                        onClick={()=>
                          setRegisteredCourses(studentRegistrations)}
                      >
                          show more</a>
                  }
                </RowFlexWrapper>:null

          }
        </FlexContainer>
        <ModalPopUp
          modalShow={showDeletePopUp}
          modalTitle={'Confirm Delete'}
          modalBody={'Are you sure you want to delete'}
          closeHandler={()=>setShowDeletePopUp(false)}
          confirmHandler={deleteRegisteredCourse}
        />
        <RescheduleModal modalShow={showRescheduleModal}
          closeHandler={()=>setShowRescheduleModal(false)}
          modalTitle={'Pick your class schedule'}
          leadId={userData.userId}
          regId={registeredId}
          lessonsForCourse={lessons}
          setShowSpinner={setShowSpinner}
          getRegisteredCourses={getRegisteredCourses}
          setFailureMessage={setFailureMessage}
          setSuccessMessage={setSuccessMessage}
          timeZone={userData.timeZone}
        />
        <ModalPopUp
          modalShow={showAssessmentsModal}
          closeHandler={() => closeAssessmentModal()}
          modalTitle={'Assignment Info'}
          assessmentBody={assignmentInfo}
        />

        {/* confirm toolkit popup */}
        {/* <ModalPopUp
          modalShow={confirmToolkitPopup}
          modalTitle={'Buy Toolkit'}
          modalBody={
            `Price - ${toolkitObject?.price} ${toolkitObject?.currencyCode}`
          }
          closeHandler={()=>setConfirmToolkitPopup(false)}
          confirmHandler={buyToolkit}
        /> */}
        {/* show schedule popup shashi*/}
        <ModalPopUp
          modalShow={showSchedulePopup}
          modalTitle={'Schedule'}
          schedule={schedule}
          closeHandler={()=>setShowSchedulePopup(false)}
          leadId = {leadId}
          regId = {ModuleRegId}
          agentType = {agentType}
          agentName = {agentName}
        />

        {/* restart module popup */}
        <ModalPopUp
          modalShow={restartModuleObject? true : false}
          modalTitle={'Restart'}
          modalBody={
            `Do you want to restart module ?`}
          closeHandler={()=>setRestartModuleObject(null)}
          confirmHandler={()=> restartModule(restartModuleObject)}
        />
      </DisplayLayoutContainer>
    </>
  );
};

RegisteredCoursesDetails.propTypes = {
  studentRegistrations: PropTypes.array,
  isLoading: PropTypes.bool,
  getRegisteredCourses: PropTypes.func,
  csAgent: PropTypes.bool,
  userData: PropTypes.object,
  salesAgent: PropTypes.bool,
  reactivationAgent: PropTypes.bool,
  susAgent: PropTypes.bool,
};
